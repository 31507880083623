<template>
  <ab-flow-base-cmp :block="block" class="video-editor-cmp relative-position" :class="classesString" :style="stylesString">
    <q-spinner
        v-if="!isReady"
        class="q-mx-auto q-my-auto"
        color="primary"
        size="2em"
        :thickness="10"
    />
    <template v-else>
      <video
          :key="videoSource"
          :src="videoData"
          class="player"
          :class="videoState"
          @timeupdate="updateSubtitle"
          @load="onVideoLoaded"
          @loadeddata="onVideoLoaded"
          @ended="onVideoEnded"
          @error="onVideoError"
          @play="onVideoPlay"
          @pause="onVideoPause"
          @seeked="onVideoSeeked"
          ref="player"
          :style="videoStyles"
          autoplay
          playsinline
          :muted="muted"
          crossorigin="anonymous"
      >Your browser does not support the video tag.</video>

      <div v-if="subtitlesTrack" class="subtitles">
        <div class="subtitles__track">{{ currentSubtitle }}</div>
      </div>

      <audio v-if="audioData" class="hidden" ref="audio" :src="audioData" :muted="muted" />

      <q-btn
          v-if="displaySoundControlIcon"
          class="sound-control-btn absolute-top-right q-mr-sm q-mb-md"
          :class="soundControlIconClasses"
          round
          :icon="muted ? 'volume_off' : 'volume_up'"
          size="sm"
          @click="muted = !muted"
      />
    </template>
  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import {renderMixins} from "../../renderMixins";
import axios from 'axios';

/**
 * Converts a time stamp in the format hh:mm:ss.mmm to seconds.
 *
 * @param {string} timeString - The time stamp to convert.
 * @returns {number} The converted time stamp in seconds.
 */
const timeToSeconds = (timeString) => {
  // Split the time string into hours, minutes, and seconds
  const parts = timeString.split(':');
  const secondsParts = parts[2].split('.');

  // Parse the hours, minutes, seconds, and milliseconds from the split parts
  const hours = parseInt(parts[0]) || 0;
  const minutes = parseInt(parts[1]) || 0;
  const seconds = parseInt(secondsParts[0]) || 0;
  const milliseconds = parseInt(secondsParts[1]) || 0;

  // Convert the time components to seconds and return the total
  return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
}

/**
 * Parses a WebVTT data string and returns an array of subtitle cues.
 *
 * @param {string} vttData - The WebVTT data string to parse.
 * @returns {Array} An array of subtitle cues, each represented as an object with properties `index`, `start`, `end`, and `text`.
 */
const parseVtt = (vttData) => {
  // Split the VTT data into lines
  const lines = vttData.trim().split(/\r?\n/);
  let cueIndex = 0;
  const subtitles = [];

  // Iterate over each line
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();

    // If the line is a number, it's the cue index
    if (/^\d+$/.test(line)) {
      cueIndex = parseInt(line);
    }
    // If the line matches the pattern for a cue timestamp, it's the start and end times for a cue
    else if (/^(\d+:)?\d+:\d+\.\d+\s*-->\s*(\d+:)?\d+:\d+\.\d+$/.test(line)) {
      // Split the line into start and end times
      const [startTime, endTime] = line.split(' --> ');

      // Convert the start and end times to seconds
      const startSeconds = timeToSeconds(startTime);
      const endSeconds = timeToSeconds(endTime);

      // The next line is the cue text
      const text = lines[i + 1].trim();

      // Add the cue to the subtitles array
      subtitles.push({
        index: cueIndex,
        start: startSeconds,
        end: endSeconds,
        text: text
      });

      // Skip the next line since we've already processed it as the cue text
      i++;
    }
  }

  // Return the array of subtitles
  return subtitles;
}

export default {
  mixins: [renderMixins],
  components: {AbFlowBaseCmp},
  inject: {
    renderer: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  props: ['block'],
  name: "VideoEditorCmp",
  data() {
    return {
      videoState: "",
      videoData: false,
      audioData: false,
      currentTime: 0, // The last known time of the video.
      currentSubtitle: '', // The current subtitle text.
      subtitles: [], // The array of subtitle cues.
      playbackFinished: false,
    };
  },

  computed: {

    /**
     * Get audio mute state
     */
    muted: {
      get() {
        return !this.renderer.a2u.storage.get("settings.sound.videoSoundEnabled", true)
      },
      set(value) {
        this.renderer.a2u.storage.set("settings.sound.videoSoundEnabled", !value)
      }
    },

    /**
     * Get video styles
     */
    videoStyles() {
      const styles = {}
      if (this.block.properties) for (const prop of Object.keys(this.block.properties)) {
        const val = this.block.properties[prop]
        if (val !== undefined) switch (prop) {
          case "video_fitting":
            styles['object-fit'] = val
            break
        }
      }

      // Return styles list
      return styles
    },

    /**
     * This computed property is used to get the video path from the block properties.
     * It retrieves the value of the 'video' property from the block's properties.
     *
     * @returns {string} The video path.
     */
    videoPath() {
      return this.getValue(this.block?.properties?.video);
    },

    /**
     * This computed property is used to determine if the video path is remote.
     * It checks if the video path starts with 'remote:'.
     *
     * @returns {boolean} True if the video path is remote, otherwise false.
     */
    isRemote() {
      const path = typeof this.videoPath === 'string' ? this.videoPath : this.videoPath?.source_url || '';
      return path.startsWith('remote:');
    },

    /**
     * This computed property is used to get the asset path of the video.
     * It uses the renderer's assetPath method to get the asset path.
     *
     * @returns {string} The asset path of the video.
     */
    videoAssetPath() {
      // Get path
      return this.renderer.a2u.assetPath(this.videoPath);
    },

    /**
     * This computed property is used to determine the source of the video.
     * If a video asset path is available, it will be used as the video source.
     * Otherwise, a default image located at "../../../assets/plugs/default-image.png" will be used.
     *
     * @returns {string} The source of the video.
     */
    videoSource() {
      return this.videoAssetPath ? this.videoAssetPath : null;
    },

    /**
     * Get the current locale from the app client configuration.
     * @return {string|null} The current locale if it exists, otherwise null.
     */
    currentLocale() {
      return this.renderer.currentLanguage;
    },

    /**
     * Get the video assets based on the current or main locale.
     * @return {object|null} The video assets if they exist, otherwise null.
     */
    videoAssets() {
      const items = (this.block?.properties?.items?.items || []);

      const assets = items.find((item) => item.language === (this.currentLocale || 'en'));

      return assets || items[0] || null;
    },

    /**
     * Get the audio track from the video assets.
     * @return {string|null} The audio track if it exists, otherwise null.
     */
    audioTrack() {
      return this.videoAssets ? this.renderer.a2u.assetPath(this.videoAssets?.audio?.source_url) : null;
    },

    /**
     * Get the subtitles track from the video assets.
     * @return {string|null} The subtitles track if it exists, otherwise null.
     */
    subtitlesTrack() {
      return this.videoAssets ? this.renderer.a2u.assetPath(this.videoAssets?.subtitles?.source_url) : null;
    },

    /**
     * Determines if the sound control icon should be displayed.
     * @return {boolean} True if the sound control icon should be displayed, otherwise false.
     */
    displaySoundControlIcon() {
      return this.audioTrack || this.block?.properties?.displaySoundControlIcon === 1;
    },

    /**
     * Computes the CSS classes for the sound control icon.
     * @return {Array} An array of CSS class names.
     */
    soundControlIconClasses() {
      return [
        `dg-background-${this.block?.properties?.soundIconBackground || 'dark'}`,
        `dg-foreground-${this.block?.properties?.soundIconColor || 'light'}`,
        {animated: this.muted},
      ];
    },

    /**
     * Palyer is ready to play
     * @return {boolean|""|null|*}
     */
    isReady() {
      return this.videoData && (!this.audioTrack || (this.audioTrack && this.audioData));
    }
  },

  methods: {

    /**
     * Load video
     * @return {Promise<void>}
     */
    async loadVideo() {

      // Load video data with axios
      if (this.videoSource) {
        if (['web', 'ios'].includes(this.renderer.a2u.platform)) {
          this.videoData = this.videoSource;
          return;
        }

        try {
          const response = await axios.get(this.videoSource, {responseType: 'blob'})
          this.videoData = URL.createObjectURL(response.data)
        } catch (e) {
          throw new Error("Error loading video", e)
        }
      }
    },

    /**
     * Load audio
     * @return {Promise<void>}
     */
    async loadAudio() {
      // Load audio data with axios
      if (this.audioTrack) {
        if (['web', 'ios'].includes(this.renderer.a2u.platform)) {
          this.audioData = this.audioTrack;
          return;
        }

        try {
          const response = await axios.get(this.audioTrack, {responseType: 'blob'})
          this.audioData = URL.createObjectURL(response.data)
        } catch (e) {
          throw new Error("Error loading audio", e)
        }
      }
    },

    /**
     * Manage video
     * @param data
     */
    manageVideo(data) {
      switch (data?.event) {
        case "restart":
          var player = this.$refs.player
          if (!player) throw new Error("Player not found")
          player.currentTime = 0;
          player.play();
          break;
      }
    },

    /**
     * On video loaded
     */
    onVideoLoaded() {
      this.videoState = "loaded"
    },
    /**
     * On video loaded
     */
    onVideoError(err) {
      console.error("Video error:", this.block, err);
      this.videoState = "loaded"
    },

    /**
     * On video ended
     */
    onVideoEnded() {
      // Stop audio track
      if (this.$refs?.audio) {
        this.$refs.audio.pause();
      }

      this.playbackFinished = true;

      this.parentDiagram.processOutgoingLinks(this, this.block.id, false, "finished")
    },

    /**
     * On video play
     */
    onVideoPlay() {
      this.videoState = "loaded"

      if (!this.$refs?.audio) {
        return;
      }

      this.playbackFinished = false;

      // Play audio track
      this.$refs.audio.play();
    },

    /**
     * On video pause
     */
    onVideoPause() {
      if (!this.$refs?.audio) {
        return;
      }

      // Pause audio track
      this.$refs.audio.pause();
    },

    /**
     * On video seeked
     */
    onVideoSeeked() {
      if (!this.$refs?.audio) {
        return;
      }

      // Sync time
      this.$refs.audio.currentTime = this.$refs.player.currentTime;
    },

    /**
     * Asynchronously loads subtitles from a given track.
     * If the `subtitlesTrack` data property is not set, the function returns immediately.
     * Otherwise, it sends a GET request to the URL of the subtitles track, parses the response data as WebVTT,
     * and assigns the parsed subtitles to the `subtitles` data property.
     * If an error occurs during this process, it logs the error message to the console.
     */
    async loadSubtitles() {
      if (!this.subtitlesTrack) {
        return;
      }

      try {
        // Send a GET request to the URL of the subtitles track
        const response = await axios.get(this.subtitlesTrack);

        // Parse the response data as WebVTT and assign the parsed subtitles to the `subtitles` data property
        this.subtitles = parseVtt(response.data);
      } catch (e) {
        // Log the error message to the console
        console.error("Error loading subtitles", e);
      }
    },

    /**
     * Updates the current subtitle based on the current time of the video.
     * If there are no subtitles or no subtitles track, it sets the current subtitle to an empty string and returns.
     * Otherwise, it calculates the current time, and if it's different from the last known time,
     * it finds the subtitle for the current time and sets it as the current subtitle.
     *
     * @param {Event} e - The event object from the `timeupdate` event of the video player.
     * @property {HTMLMediaElement} e.target - The video player that triggered the event.
     */
    updateSubtitle(e) {
      // If there are no subtitles or no subtitles track, set the current subtitle to an empty string and return
      if (!this.subtitles.length || !this.subtitlesTrack) {
        this.currentSubtitle = '';
        return;
      }

      // Calculate the current time
      const currentTime = Math.floor(e.target.currentTime);

      // If the current time is different from the last known time
      if (currentTime !== this.currentTime) {
        // Update the last known time
        this.currentTime = currentTime;

        // Find the subtitle for the current time
        const currentSubtitle = this.subtitles.find(subtitle => currentTime >= subtitle.start && currentTime < subtitle.end);

        // Set the found subtitle as the current subtitle, or an empty string if no subtitle was found
        this.currentSubtitle = currentSubtitle ? currentSubtitle.text : '';
      }
    },
  },

  watch: {
    async subtitlesTrack() {
      await this.loadSubtitles();
    },

    muted() {
      if (!this.muted && this.$refs.audio && !this.playbackFinished) {
        this.$refs.audio.play();
      }
    },
  },

  created() {
    // Set video time according to animation time
    this.$watch('animation_frame', (val) => {
      this.$refs.player.currentTime = val
    })

    // Register management event
    this.parentDiagram.registerHandler(this.block.id, 'manage', this.manageVideo)
  },

  /**
   * Mounted
   * @return {Promise<void>}
   */
  async mounted() {

    // Watch video changes and load full video
    this.$watch('videoSource', () => {
      this.loadVideo();
    }, {immediate: true})

    // Watch audio changes and load full audio
    this.$watch('audioTrack', () => {
      this.loadAudio();
    }, {immediate: true})

    // Load subtitles
    await this.loadSubtitles();
  },

  beforeUnmount() {
    // Unregister management event
    this.parentDiagram.unregisterHandler(this.block.id, 'management')

    if (this.$refs.player) {
      // Stop video
      var player = this.$refs.player
      player.muted = true;
      player.pause();
    }
  },
}

</script>

<style lang="scss">

@keyframes pulse {
  0% {
    transform: scale(1); /* Начальное и конечное состояние */
    background: white;
    color:black;
    border:1px solid black;
  }
  100% {
    transform: scale(1.2); /* Увеличение на 10% в середине анимации */
    background: black;
    color:white;
    border:1px solid white;
  }
}

.sound-control-btn {
  margin-top:10px;
  margin-right:10px;
  z-index: 1;
}

.sound-control-btn.animated {
  animation: pulse 2s alternate infinite;
}

.video-editor-cmp {
  display: flex;
  overflow: hidden;

  .player.loaded {
    visibility: visible;
  }

  .player {
    position: absolute;
    width: 100%;
    height: 100%;
    visibility: hidden;
  }

  .subtitles {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.4em;
    pointer-events: none;

    &__track {
      text-align: center;
      white-space: pre-line;
    }
  }
}

</style>
