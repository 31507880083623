import {DelayedOperationProcessor} from "a2u-renderer-common/src/processors/Logic/Code/DelayedOperationProcessor";
import {AbTestProcessor} from "a2u-renderer-common/src/processors/Analytics/AbTestProcessor";
import {FakeLogicProcessor} from "a2u-renderer-common/src/processors/Logic/Code/FakeLogicProcessor";
import {LogicConditionProcessor} from "a2u-renderer-common/src/processors/Logic/Code/LogicConditionProcessor";
import {StorageSetValueProcessor} from "a2u-renderer-common/src/processors/Storage/StorageSetValueProcessor";
import {StorageGetValueProcessor} from "a2u-renderer-common/src/processors/Storage/StorageGetValueProcessor";
import {VarOperationProcessor} from "a2u-renderer-common/src/processors/Logic/Code/VarOperations/VarOperationProcessor";
import {DbSaveProcessor} from "a2u-renderer-common/src/processors/DB/DbSaveProcessor";
import {DbQueryProcessor} from "a2u-renderer-common/src/processors/DB/DbQueryProcessor";
import {DbDeleteProcessor} from "a2u-renderer-common/src/processors/DB/DbDeleteProcessor";
import {CodeFunctionProcessor} from 'a2u-renderer-common/src/processors/Logic/Code/CodeFunctionProcessor.js';
import {ImageExplainerProcessor} from 'a2u-renderer-common/src/processors/AI/ImageExplainer/ImageExplainerProcessor';
import {ImageGeneratorProcessor} from 'a2u-renderer-common/src/processors/AI/ImageGenerator/ImageGeneratorProcessor';
import {TextGeneratorProcessor} from 'a2u-renderer-common/src/processors/AI/TextGenerator/TextGeneratorProcessor';
import FragmentEditorCmp from "./Containers/Fragment/FragmentEditorCmp";
import ButtonEditorCmp from "./Basic/Button/ButtonEditorCmp";
import FormEditorCmp from "./Form/Form/FormEditorCmp";
import InputEditorCmp from "./Form/InputEditorCmp";
import {FragmentProcessor} from "./Containers/Fragment/FragmentProcessor";
import BannerEditorCmp from "./Monetization/BannerEditorCmp";
import {CheckPermissionProcessor} from "./Device/CheckPermissionProcessor";
import {RequestPermissionProcessor} from "./Device/RequestPermissionProcessor";
import {DiagramComponentProcessor} from "./Logic/DiagramComponent/DiagramComponentProcessor";
import {CustomEventProcessor} from "./Logic/Code/CustomEventProcessor";
import TeleportEditorCmp from "./Containers/Teleport/TeleportEditorCmp.vue";
import SubmitEditorCmp from "./Form/SubmitEditorCmp.vue";
import {FormProcessor} from "./Form/Form/FormProcessor";
import DiagramComponentEditorCmp from "./Logic/DiagramComponent/DiagramComponentEditorCmp.vue";
import NativeComponentEditorCmp from "./Logic/NativeComponent/NativeComponentEditorCmp.vue";
import {StorageConditionProcessor} from "./Storage/StorageConditionProcessor";
import {DeviceNavigatorProcessor} from "./Device/DeviceNavigatorProcessor";
import {CheckRoleFunctionProcessor} from "./Auth/CheckRoleFunctionProcessor";
import {SignInFunctionProcessor} from "./Auth/SignInFunctionProcessor";
import {SignUpFunctionProcessor} from "./Auth/SignUpFunctionProcessor";
import {LogoutFunctionProcessor} from "./Auth/LogoutFunctionProcessor";
import ContainerEditorCmp from "./Basic/Container/ContainerEditorCmp.vue";
import TextEditorCmp from "./Basic/Text/TextEditorCmp.vue";
import ImageEditorCmp from "./Basic/Image/ImageEditorCmp.vue";
import VideoEditorCmp from "./Basic/Video/VideoEditorCmp.vue";
import {PlaySoundProcessor} from "./Sound/PlaySoundProcessor";
import FullscreenAdEditorCmp from "./Monetization/FullscreenAdEditorCmp.vue";
import {FullscreenAdProcessor} from "./Monetization/FullscreenAdProcessor";
import WidgetEditorCmp from "./Containers/Widget/WidgetEditorCmp.vue";
import LottieEditorCmp from "./Basic/Lottie/LottieEditorCmp.vue";
import {SoundManagerProcessor} from "./Sound/SoundManagerProcessor";
import {VibrateProcessor} from "./Sound/VibrateProcessor";
import {WidgetProcessor} from "./Containers/Widget/WidgetProcessor";
import {NativeComponentProcessor} from "./Logic/NativeComponent/NativeComponentProcessor";
import RichTextEditorCmp from "./Basic/ReachText/RichTextEditorCmp.vue";
import IconEditorCmp from "./Basic/Icon/IconEditorCmp.vue";
import NavigationPanelEditorCmp from "./Navigation/NavigationPanelEditorCmp.vue";
import AppHeaderEditorCmp from "./Navigation/AppHeaderEditorCmp.vue";
import RepeaterEditorCmp from "./DataViews/ListView/RepeaterEditorCmp.vue";
import {RepeaterProcessor} from "./DataViews/ListView/RepeaterProcessor";
import SeparatorEditorCmp from "./Basic/Separator/SeparatorEditorCmp.vue";
import FABEditorCmp from "./Navigation/FABEditorCmp.vue";
import {RegularDialogProcessor} from "./Navigation/RegularDialogProcessor";
import SelectEditorCmp from "./Form/SelectEditorCmp.vue";
import CarouselEditorCmp from "./Containers/Carousel/CarouselEditorCmp.vue";
import {InAppPurchaseProcessor} from "./Monetization/InAppPurchaseProcessor";
import CheckBoxEditorCmp from "./Form/CheckBoxEditorCmp.vue";
import CircularProgressEditorCmp from "./Basic/Progress/CircularProgressEditorCmp.vue";
import LinearProgressEditorCmp from "./Basic/Progress/LinearProgressEditorCmp.vue";
import RouletteSelectorEditorCmp from "./Form/RouletteSelector/RouletteSelectorEditorCmp.vue";
import TreeViewEditorCmp from "./DataViews/TreeView/TreeViewEditorCmp.vue";
import {HasInAppPurchaseProcessor} from "./Monetization/HasInAppPurchaseProcessor";
import ChartViewEditorCmp from "./DataViews/Chart/ChartViewEditorCmp.vue";
import WebBrowserEditorCmp from "./Device/WebBrowserEditorCmp.vue";
import {OpenEmailProcessor} from "./Device/OpenEmailProcessor";
import {SystemAlarmProcessor} from "./Notifications/SystemAlarmProcessor";
import InAppProductsListEditorCmp from "./Monetization/InAppProductsListEditorCmp.vue";
import {LoadingProcessor} from "./Navigation/LoadingProcessor";
import {VideoProcessor} from "./Basic/Video/VideoProcessor";
import WidgetRendererEditorCmp from "./Navigation/WidgetRendererEditorCmp.vue";
import SliderEditorCmp from "./Form/SliderEditorCmp.vue";
import TableViewEditorCmp from "./DataViews/TableView/TableViewEditorCmp.vue";
import RatingEditorCmp from "./Basic/Rating/RatingEditorCmp.vue";
import {RequestAppReviewProcessor} from "./Device/RequestAppReviewProcessor";
import DatePickerEditorCmp from "./Form/DatePickerEditorCmp.vue";
import {StopSoundProcessor} from "./Sound/StopSoundProcessor";
import {GetAnAppPurchasedAmountProcessor} from "./Monetization/GetAnAppPurchasedAmountProcessor";
import FileUploaderEditorCmp from "./Form/FileUploaderEditorCmp.vue";
import {DbSubscribeProcessor} from "./DB/DbSubscribeProcessor";
import {CheckAdConsentProcessor} from "./Monetization/CheckAdConsentProcessor";
import {ShowAdConsentProcessor} from "./Monetization/ShowAdConsentProcessor";
import {CloseModalProcessor} from "./Navigation/CloseModalProcessor";
import ContentEditorEditorCmp from "./Basic/ContentEditor/ContentEditorEditorCmp.vue";
import {InAppManageSubsProcessor} from "./Monetization/InAppManageSubsProcessor";
import {InAppRestorePurchasesProcessor} from "./Monetization/InAppRestorePurchasesProcessor";
import DateWeeklyPickerEditorCmp from './Form/DateWeeklyPickerEditorCmp.vue';
import {ShareDialogProcessor} from './Device/ShareDialogProcessor';
import {KeepAwakeProcessor} from './Device/KeepAwakeProcessor';
import {CheckPrivacyOptionsProcessor} from './Monetization/PrivacyOptions/CheckPrivacyOptionsProcessor';
import {ShowPrivacyOptionsProcessor} from './Monetization/PrivacyOptions/ShowPrivacyOptionsProcessor';
import {CheckTrialPeriodProcessor} from './Monetization/CheckTrialPeriodProcessor';
import NativeAdsEditorCmp from './Monetization/NativeAdsEditorCmp.vue';
import ChatEditorCmp from './ChatBot/ChatEditorCmp.vue';
import {ChatProcessor} from './ChatBot/ChatProcessor';
import {EnableInAppTestModeProcessor} from "./Monetization/EnableInAppTestModeProcessor";
import {AlarmCheckPermissionProcessor} from './Notifications/AlarmCheckPermissionProcessor';
import {AlarmRequestPermissionProcessor} from './Notifications/AlarmRequestPermissionProcessor';
import {ResourcePreloaderProcessor} from './Device/ResourcePreloaderProcessor';
import ThreeDSceneEditorCmp from './ThreeD/ThreeDSceneEditorCmp.vue';
import ThreeDObjectEditorCmp from './ThreeD/ThreeDObjectEditorCmp.vue';
import {WebSocketListenerProcessor} from './Logic/WebSocketListener/WebSocketListenerProcessor';
import {ForEachProcessor} from "a2u-renderer-common/src/processors/Logic/ForEach/ForEachProcessor";
import IntersectionEditorCmp from './Analytics/Intersection/IntersectionEditorCmp.vue';
import {SendEmailProcessor} from './Logic/SendEmail/SendEmailProcessor';
import {ImagePickerProcessor} from './Device/ImagePickerProcessor';
import {RequestCameraPermissionsProcessor} from './Device/RequestCameraPermissionsProcessor';
import {MarketingEventProcessor} from './Analytics/MarketingEventProcessor';
import {PdfGeneratorProcessor} from './Logic/PdfGenerator/PdfGeneratorProcessor';
import {ChatSendMessageProcessor} from "./ChatBot/ChatSendMessageProcessor";
import ContentSwiperStackEditorCmp from './DataViews/ContentSwiperStack/ContentSwiperStackEditorCmp.vue';
import {VoiceChatProcessor} from "./ChatBot/VoiceChat/VoiceChatProcessor";
import {ForgotPasswordProcessor} from './Auth/ForgotPasswordProcessor';
import DayCalendarEditorCmp from './DataViews/DayCalendar/DayCalendarEditorCmp.vue';
import {MailingListManagerProcessor} from './Logic/MailingListManager/MailingListManagerProcessor';
import {NavigateToProcessor} from "a2u-renderer-common/src/processors/Navigation/NavigateToProcessor";
import {AIFunctionProcessor} from "a2u-renderer-common/src/processors/Logic/Code/AIFunctionProcessor";

export const designerComponentsList = [
    {
        title: "Container",
        type: "Container",
        allowed: ['*'],
        component: ContainerEditorCmp,
    },
    {
        title: "Fragment",
        type: "Fragment",
        allowed: ['*'],
        component: FragmentEditorCmp,
        processor: FragmentProcessor
    },
    {
        title: "Navigate to route",
        type: "NavigateTo",
        purpose: ["ui"],
        processor: NavigateToProcessor,
    },
    {
        title: "Button",
        type: "Button",
        allowed: ['*'],
        component: ButtonEditorCmp,
    },
    {
        title: "FAB",
        type: "FAB",
        allowed: ['*'],
        component: FABEditorCmp,
    },
    {
        title: "Text",
        type: "Text",
        allowed: ['*'],
        component: TextEditorCmp,
    },
    {
        title: "Form",
        type: "Form",
        allowed: ['*'],
        component: FormEditorCmp,
        processor: FormProcessor
    },
    {
        title: "Submit button",
        type: "SubmitButton",
        allowed: ['*'],
        component: SubmitEditorCmp
    },
    {
        title: "Text input",
        type: "TextInput",
        allowed: ['*'],
        component: InputEditorCmp,
    },
    {
        title: "File uploader",
        type: "FileUploader",
        allowed: ['*'],
        component: FileUploaderEditorCmp,
    },
    {
        title: "Date picker",
        type: "DatePicker",
        allowed: ['*'],
        component: DatePickerEditorCmp,
    },
    {
        title: "Date weekly picker",
        type: "DateWeeklyPicker",
        allowed: ['*'],
        component: DateWeeklyPickerEditorCmp,
    },
    {
        title: "Rating",
        type: "Rating",
        allowed: ['*'],
        component: RatingEditorCmp,
    },
    {
        title: "Checkbox",
        type: "CheckBox",
        allowed: ['*'],
        component: CheckBoxEditorCmp,
    },
    {
        title: "Text input",
        type: "TextInput",
        allowed: ['*'],
        component: InputEditorCmp,
    },
    {
        title: "Select box",
        type: "SelectBox",
        allowed: ['*'],
        component: SelectEditorCmp
    },
    {
        title: "Slider",
        type: "Slider",
        allowed: ['*'],
        component: SliderEditorCmp
    },
    {
        title: "Roulette selector",
        type: "RouletteSelector",
        allowed: ['*'],
        component: RouletteSelectorEditorCmp
    },
    {
        title: "Logic condition",
        type: "LogicCondition",
        allowed: ['*'],
        processor: LogicConditionProcessor
    },
    {
        title: "Device navigator",
        type: "DeviceNavigator",
        allowed: ['*'],
        processor: DeviceNavigatorProcessor
    },

    {
        title: "Storage condition",
        type: "StorageCondition",
        allowed: ['*'],
        processor: StorageConditionProcessor
    },

    {
        title: "Storage set value",
        type: "StorageSetValue",
        allowed: ['*'],
        processor: StorageSetValueProcessor
    },

    {
        title: "Storage get value",
        type: "StorageGetValue",
        allowed: ['*'],
        processor: StorageGetValueProcessor
    },

    {
        title: "Rich text",
        type: "RichText",
        allowed: ['*'],
        component: RichTextEditorCmp,
    },

    {
        title: "Content editor",
        type: "ContentEditor",
        allowed: ['*'],
        component: ContentEditorEditorCmp,
    },

    {
        title: "LottieAnimation",
        type: "Lottie",
        allowed: ['*'],
        component: LottieEditorCmp
    },
    //ADs components
    {
        title: "Fullscreen ad",
        type: "FullscreenAd",
        allowed: [],
        component: FullscreenAdEditorCmp,
        processor: FullscreenAdProcessor
    },
    {
        title: "Check ad consent",
        type: "CheckAdConsent",
        allowed: [],
        processor: CheckAdConsentProcessor
    },
    {
        title: "Show Ad consent",
        type: "ShowAdConsent",
        allowed: [],
        processor: ShowAdConsentProcessor
    },
    {
        title: "In app purchase",
        type: "InAppPurchase",
        allowed: [],
        processor: InAppPurchaseProcessor
    },
    {
        title: "Manage subscriptions",
        type: "InAppManageSubs",
        allowed: [],
        processor: InAppManageSubsProcessor
    },
    {
        title: "Restore purchases",
        type: "InAppRestorePurchases",
        allowed: [],
        processor: InAppRestorePurchasesProcessor
    },
    {
        title: "In app purchases list",
        type: "InAppProductsList",
        allowed: [],
        component: InAppProductsListEditorCmp
    },
    {
        title: "Get an app purchased amount",
        type: "GetAnAppPurchasedAmount",
        allowed: [],
        processor: GetAnAppPurchasedAmountProcessor
    },
    {
        title: "Has in app purchase",
        type: "HasInAppPurchase",
        allowed: [],
        processor: HasInAppPurchaseProcessor
    },
    {
        title: "Enable In App test mode",
        type: "EnableInAppTestMode",
        processor: EnableInAppTestModeProcessor,
    },
    {
        title: "Banner",
        type: "AdBanner",
        allowed: ['*'],
        component: BannerEditorCmp,
    },
    {
        title: "Native ads",
        type: "NativeAds",
        allowed: ['*'],
        component: NativeAdsEditorCmp,
    },
    {
        title: "Image",
        type: "Image",
        allowed: ['*'],
        component: ImageEditorCmp,
    },
    {
        title: "Icon",
        type: "Icon",
        allowed: ['*'],
        component: IconEditorCmp,
    },
    {
        title: "Video",
        type: "Video",
        allowed: ['*'],
        component: VideoEditorCmp,
        processor: VideoProcessor
    },
    {
        title: "Check permission",
        type: "CheckPermission",
        allowed: [],
        processor: CheckPermissionProcessor
    },
    {
        title: "Request app review",
        type: "RequestAppReview",
        allowed: [],
        processor: RequestAppReviewProcessor
    },
    {
        title: "Web browser",
        type: "WebBrowser",
        allowed: [],
        component: WebBrowserEditorCmp
    },
    {
        title: "Open email",
        type: "OpenEmail",
        allowed: [],
        processor: OpenEmailProcessor
    },
    {
        title: "System alarm",
        type: "SystemAlarm",
        allowed: [],
        processor: SystemAlarmProcessor
    },
    {
        title: "Alarm: check permission",
        type: "AlarmCheckPermission",
        allowed: [],
        processor: AlarmCheckPermissionProcessor,
    },
    {
        title: "Alarm: request permission",
        type: "AlarmRequestPermission",
        allowed: [],
        processor: AlarmRequestPermissionProcessor,
    },
    {
        title: "Request permission",
        type: "RequestPermission",
        allowed: [],
        processor: RequestPermissionProcessor
    },
    {
        title: "Diagram component",
        type: "DiagramComponent",
        allowed: ['*'],
        processor: DiagramComponentProcessor,
        component: DiagramComponentEditorCmp
    },
    {
        title: "Widget renderer",
        type: "WidgetRenderer",
        allowed: ['*'],
        component: WidgetRendererEditorCmp
    },
    {
        title: "Teleport",
        type: "Teleport",
        allowed: ['*'],
        component: TeleportEditorCmp,
    },
    {
        title: "Carousel",
        type: "Carousel",
        allowed: ['*'],
        component: CarouselEditorCmp,
    },
    {
        title: "Custom event",
        type: "CustomEvent",
        allowed: ['*'],
        processor: CustomEventProcessor
    },
    {
        title: "Native component",
        type: "NativeComponent",
        allowed: ['*'],
        component: NativeComponentEditorCmp,
        processor: NativeComponentProcessor
    },
    {
        title: "Check user role",
        type: "CheckRoleFunction",
        allowed: [],
        processor: CheckRoleFunctionProcessor
    },

    {
        title: "Sign in function",
        type: "SignInFunction",
        allowed: [],
        processor: SignInFunctionProcessor
    },

    {
        title: "Sign up function",
        type: "SignUpFunction",
        allowed: [],
        processor: SignUpFunctionProcessor
    },

    {
        title: "Logout function",
        type: "LogoutFunction",
        allowed: [],
        processor: LogoutFunctionProcessor
    },
    {
        title: "Play sound",
        type: "PlaySound",
        allowed: [],
        processor: PlaySoundProcessor
    },
    {
        title: "Stop sound",
        type: "StopSound",
        allowed: [],
        processor: StopSoundProcessor
    },
    {
        title: "Vibrate",
        type: "Vibrate",
        allowed: [],
        processor: VibrateProcessor
    },
    {
        title: "Sound manager",
        type: "SoundManager",
        allowed: [],
        processor: SoundManagerProcessor
    },
    {
        title: "Widget",
        type: "Widget",
        allowed: [],
        component: WidgetEditorCmp,
        processor: WidgetProcessor
    },
    {
        title: "Navigation panel",
        type: "NavigationPanel",
        allowed: ['*'],
        component: NavigationPanelEditorCmp
    },
    {
        title: "App header",
        type: "AppHeader",
        allowed: ['*'],
        component: AppHeaderEditorCmp
    },
    {
        title: "Repeater",
        type: "Repeater",
        allowed: ['*'],
        component: RepeaterEditorCmp,
        processor: RepeaterProcessor
    },
    {
        title: "Table View",
        type: "TableView",
        allowed: ['*'],
        component: TableViewEditorCmp
    },
    {
        title: "Tree view",
        type: "TreeView",
        allowed: ['*'],
        component: TreeViewEditorCmp
    },
    {
        title: "Chart view",
        type: "ChartView",
        allowed: ['*'],
        component: ChartViewEditorCmp
    },
    {
        title: "Separator",
        type: "Separator",
        allowed: ['*'],
        component: SeparatorEditorCmp
    },
    {
        title: "Circular progress",
        type: "CircularProgress",
        allowed: ['*'],
        component: CircularProgressEditorCmp
    },
    {
        title: "Linear progress",
        type: "LinearProgress",
        allowed: ['*'],
        component: LinearProgressEditorCmp
    },
    {
        title: "DB Save",
        type: "DbSave",
        allowed: ['*'],
        processor: DbSaveProcessor
    },
    {
        title: "DB Delete",
        type: "DbDelete",
        allowed: ['*'],
        processor: DbDeleteProcessor
    },
    {
        title: "DB Query",
        type: "DbQuery",
        allowed: ['*'],
        processor: DbQueryProcessor
    },
    {
        title: "DB Subscribe",
        type: "DbSubscribe",
        allowed: ['*'],
        processor: DbSubscribeProcessor
    },
    {
        title: "Regular dialog",
        type: "RegularDialog",
        allowed: ['*'],
        processor: RegularDialogProcessor
    },
    {
        title: "Close modal",
        type: "CloseModal",
        allowed: ['*'],
        processor: CloseModalProcessor
    },
    {
        title: "Code function",
        type: "CodeFunction",
        allowed: ['*'],
        processor: CodeFunctionProcessor
    },
    {
        title: "AI Action",
        type: "AIFunction",
        allowed: ['*'],
        processor: AIFunctionProcessor
    },
    {
        title: "Logic block",
        type: "FakeLogic",
        allowed: ['*'],
        processor: FakeLogicProcessor
    },
    {
        title: "Var operation",
        type: "VarOperation",
        allowed: ['*'],
        processor: VarOperationProcessor
    },
    {
        title: "Delayed operation",
        type: "DelayedOperation",
        allowed: ['*'],
        processor: DelayedOperationProcessor
    },
    {
        title: "AtTest",
        type: "AbTest",
        allowed: ['*'],
        processor: AbTestProcessor
    },
    {
        title: "Loading",
        type: "Loading",
        allowed: ['*'],
        processor: LoadingProcessor
    },
    {
        title: "Share Dialog",
        type: "ShareDialog",
        allowed: [],
        processor: ShareDialogProcessor,
    },
    {
        title: "Keep Awake",
        type: "KeepAwake",
        allowed: [],
        processor: KeepAwakeProcessor,
    },
    {
        title: "Check privacy options",
        type: "CheckPrivacyOptions",
        allowed: [],
        processor: CheckPrivacyOptionsProcessor,
    },
    {
        title: "Show privacy options",
        type: "ShowPrivacyOptions",
        allowed: [],
        processor: ShowPrivacyOptionsProcessor,
    },
    {
        title: "Check trial period",
        type: "CheckTrialPeriod",
        allowed: [],
        processor: CheckTrialPeriodProcessor,
    },
    {
        title: "Send message to chat",
        type: "ChatSendMessage",
        allowed: ['*'],
        processor: ChatSendMessageProcessor,
    },
    {
        title: "Chat",
        type: "Chat",
        allowed: ['*'],
        component: ChatEditorCmp,
        processor: ChatProcessor,
    },
    {
        title: "Voice chat",
        type: "VoiceChat",
        allowed: ['*'],
        processor: VoiceChatProcessor,
    },

    {
        title: "Resource preloader",
        type: "ResourcePreloader",
        allowed: ['*'],
        processor: ResourcePreloaderProcessor,
    },
    {
        title: "3D scene",
        type: "ThreeDScene",
        allowed: ['*'],
        component: ThreeDSceneEditorCmp,
    },
    {
        title: "3D object",
        type: "ThreeDObject",
        allowed: ['*'],
        component: ThreeDObjectEditorCmp,
    },
    {
        title: "WebSocket listener",
        type: "WebSocketListener",
        allowed: ['*'],
        processor: WebSocketListenerProcessor,
    },
    {
        title: "ForEach",
        type: "ForEach",
        allowed: ['*'],
        processor: ForEachProcessor,
    },
    {
        title: "Intersection",
        type: "Intersection",
        allowed: ['*'],
        component: IntersectionEditorCmp,
    },
    {
        title: "Send email",
        type: "SendEmail",
        allowed: ['*'],
        processor: SendEmailProcessor,
    },
    {
        title: "Image picker",
        type: "ImagePicker",
        allowed: ['*'],
        processor: ImagePickerProcessor,
    },
    {
        title: "Request camera permissions",
        type: "RequestCameraPermissions",
        allowed: ['*'],
        processor: RequestCameraPermissionsProcessor,
    },
    {
        title: "Marketing event",
        type: "MarketingEvent",
        allowed: ['*'],
        processor: MarketingEventProcessor,
    },
    {
        title: "Image explainer",
        type: "ImageExplainer",
        allowed: ['*'],
        processor: ImageExplainerProcessor,
    },
    {
        title: "Image generator",
        type: "ImageGenerator",
        allowed: ['*'],
        processor: ImageGeneratorProcessor,
    },
    {
        title: "PDF generator",
        type: "PdfGenerator",
        allowed: ['*'],
        processor: PdfGeneratorProcessor,
    },
    {
        title: "Text generator",
        type: "TextGenerator",
        allowed: ['*'],
        processor: TextGeneratorProcessor,
    },
    {
        title: "Content swiper stack",
        type: "ContentSwiperStack",
        allowed: ['*'],
        component: ContentSwiperStackEditorCmp,
    },
    {
        title: "Forgot password",
        type: "ForgotPassword",
        allowed: ['*'],
        processor: ForgotPasswordProcessor,
    },
    {
        title: "Day calendar",
        type: "DayCalendar",
        allowed: ['*'],
        component: DayCalendarEditorCmp,
    },
    {
        title: "Mailing list manager",
        type: "MailingListManager",
        allowed: ['*'],
        processor: MailingListManagerProcessor,
    },

]
