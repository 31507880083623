<template>
  <div>
    <q-input outlined v-model="sqlQuery" autogrow label="SQL Query">
      <template #after>
        <q-btn square color="positive" icon="play_arrow" @click="executeSql" />
      </template>
    </q-input>

    <q-banner v-if="error" dense inline-actions class="text-white bg-red q-mt-md">
      {{ error }}
    </q-banner>

    <q-markup-table class="q-mt-md" dense>
      <thead v-if="tableItems.length">
      <tr>
        <th
          v-for="header in tableHeaders"
          :key="header"
          class="text-left"
          v-text="header"
        />
      </tr>
      </thead>
      <tbody>
      <tr v-if="!tableItems.length">
        <td colspan="10" class="text-center text-grey">
          No data
        </td>
      </tr>
      <tr v-for="(item, idx) in tableItems" :key="idx">
        <td v-for="value in item" :key="value" v-text="value" />
      </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>

<script>
export default {
  name: 'SqlPlayground',

  inject: ['renderer'],

  data() {
    return {
      sqlQuery: '',
      error: null,
      queryResult: [],
    };
  },

  computed: {
    /**
     * Computes the headers for the table based on the query result.
     * @returns {string[]} An array of table header names.
     */
    tableHeaders() {
      if (!this.queryResult.length) {
        return [];
      }

      return Object.keys(this.queryResult[0]);
    },

    /**
     * Computes the items for the table based on the query result.
     * @returns {Array} An array of table row values.
     */
    tableItems() {
      return this.queryResult.map((item) => Object.values(item));
    },
  },

  methods: {
    /**
     * Resets the state of the component by clearing error messages and query results.
     */
    resetState() {
      this.error = null;
      this.queryResult = [];
    },

    /**
     * Retrieves the database instance from the renderer.
     *
     * @returns {Object|boolean} The database instance if available, otherwise false.
     */
    getDb() {
      try {
        const dbs = Object.values(this.renderer?.a2u?.source?.databases || {});

        return this.renderer?.a2u?.dbs?.[dbs?.[0]?.id] || false;
      } catch (e) {
        return false;
      }
    },

    /**
     * Executes the SQL query entered by the user.
     * Shows a loading indicator while the query is being executed.
     * Resets the state before execution.
     * Handles errors and updates the error message if the query fails.
     *
     * @async
     */
    async executeSql() {
      try {
        this.$q.loading.show();

        this.resetState();

        if (!this.sqlQuery) {
          throw new Error('SQL query is empty');
        }

        const db = this.getDb();

        if (!db) {
          throw new Error('Database is not initialized');
        }

        this.queryResult = await db.rawQuery(this.sqlQuery);

        console.log('Query result:', this.queryResult);
      } catch (e) {
        console.error('Error executing SQL query:', e);

        this.error = e.message;
      } finally {
        this.$q.loading.hide();
      }
    },
  },

  created() {
    this.resetState();
  },
}
</script>
