import {StorageValueManger} from 'a2u-renderer-common/src/utils/storageValueManger';
import {constants} from '../constants';

export const renderMixins = {

    inject: {
        renderer: {
            type: Object, default: {}
        }, parentDiagram: {
            type: Object, default: {}
        }, parentFragment: {
            type: Object, default: {}
        }, parentWidget: {
            type: Object, default: null
        }, dataContext: {
            type: Object, default: {}
        },
        injections: {
            default: () => ({}),
        },
    },

    computed: {
        /**
         * This computed property is a method that creates a new instance of the StorageValueManger class.
         * It initializes the instance with an object containing the following properties:
         * - storageMeta: The meta information of the storage from the renderer's source.
         * - dataContext: The current data context.
         * - currentDiagram: The current diagram.
         * - valueExtractor: A function that extracts the value from a given object.
         * - translator: The translator function from the renderer's app client.
         *
         * @returns {StorageValueManger} A new instance of the StorageValueManger class.
         */
        storageValueManager() {
            return new StorageValueManger({
                owner: this,
                storageMeta: this.renderer?.a2u?.source?.storage?.meta,
                dataContext: this.dataContext,
                currentDiagram: this.currentDiagram,
                valueExtractor: (v) => v?.value,
                translator: this.renderer?.app?.client?.translate,
            });
        },

        /**
         * Get dynamic panel styles
         */
        dynamicPanelStyles() {

            // Check if panel style is set
            if (this.block?.properties?.panelStyle) {
                // Get panel style id
                const panelStyleId = this.getValue(this.block?.properties?.panelStyle)

                // Load panel styles
                return this.renderer.a2u.getPanelStylesById(panelStyleId) || []
            }

            // Return empty list
            return []
        },


        /**
         * Get current diagram
         * @return {*}
         */
        currentDiagram() {
            return this.block?.type === 'DiagramComponent' ? this : this.parentDiagram
        },

        /**
         * Generate classes string
         * @return {string}
         */
        classesString() {
            return this.getClasses(false, false);
        },

        /**
         * Generate styles string
         * @return {string}
         */
        stylesString() {
            const resArr = {}
            const props = Object.assign({}, this.block.properties, this.dynamicPanelStyles);

            // Properties
            if (this.block.properties) {
                for (let [propName, propData] of Object.entries(props)) {

                    // Check if prop is dynamic
                    if (typeof propData === 'object' && propData?.marker === 'dyn-prop') {
                        // Get value
                        const value = this.interpretString(propData)
                        if (value !== undefined) propData = value
                    }

                    // Apply styles
                    if (propData !== false && propData !== undefined && propData !== 'none') switch (propName) {
                      /*case "fontSize":
                          resArr[`font-size`] = `${propData}px`
                          break;*/
                        case "controlTextColor":
                            resArr[`--control-text-color`] = `var(--foreground-color-${propData})`
                            break;
                        case "controlFocusColor":
                            resArr[`--control-focus-color`] = `var(--foreground-color-${propData})`
                            break;
                        case "controlUnfocusedColor":
                            resArr[`--control-unfocused-color`] = `var(--foreground-color-${propData})`
                            break;
                        case "textShadow":
                            resArr[`text-shadow`] = `${propData.left}px ${propData.top}px ${propData.color}`
                            break;
                        case "boxShadow":
                            resArr[`box-shadow`] = `${propData.x}px ${propData.y}px ${propData.blur}px ${propData.spread}px ${propData.color}`
                            break;
                        case "lineHeight":
                            resArr[`line-height`] = propData
                            break;
                        case "border":
                            resArr['border'] = `${propData.weight ? propData.weight : 0}px solid ${propData.color}`
                            break;
                        case "width":
                            resArr['width'] = propData
                            break;
                        case "height":
                            resArr['height'] = propData
                            break;
                        case "disableEvents":
                            resArr['pointer-events'] = propData === 1 ? 'none' : 'auto'
                            break;
                        case "minWidth":
                            resArr['min-width'] = propData
                            break;
                        case "minHeight":
                            resArr['min-height'] = propData
                            break;
                        case "maxWidth":
                            resArr['max-width'] = propData
                            break;
                        case "maxHeight":
                            resArr['max-height'] = propData
                            break;
                        case "background":

                            // Image background
                            if (propData && propData.type === 'image') {
                                resArr['background-image'] = `url(${this.getAssetValue(propData.image)})`
                                resArr['background-size'] = propData.backgroundSize;
                                resArr['background-position'] = propData.backgroundPosition;
                                resArr['background-repeat'] = 'no-repeat';
                            }


                            if (propData && propData.type === 'color') resArr['background-color'] = propData.color
                            if (propData && propData.type === 'gradient') resArr['background'] = `linear-gradient(${propData.radius}deg, ${propData.fromColor} 0%, ${propData.toColor} 100%)`
                            break;

                        case "flexWrap":
                            resArr['flex-wrap'] = propData
                            break;
                        case "textAlign":
                            resArr['text-align'] = propData
                            break;
                        case "textDecoration":
                            resArr['text-decoration'] = propData
                            break;
                        case "blur":
                            // Apply blur
                            resArr['filter'] = `blur(${constants.sizes_to_pixels[propData] || 0}px)`;
                            break;
                        case 'maskImage':
                            resArr['mask-image'] = `url(${this.getAssetValue(propData)})`;
                            resArr['mask-size'] = `100% 100%`;
                            resArr['mask-repeat'] = `no-repeat`;
                            break;
                    }
                }

                // Apply animation
                const animStyles = this.applyAnimation()
                Object.keys(animStyles).map(k => resArr[k] = animStyles[k]);
            }

            //console.log(Object.keys(resArr).map(k => `${k} = ${resArr[k]}`).join(" ; "))
            // Return string
            return resArr//Object.keys(resArr).map(k => `${k} : ${resArr[k]}`).join(" ; ")
        },

        /**
         * Computes dynamic properties for the component.
         * Iterates over the block properties and sets the appropriate bindings.
         *
         * @returns {Object} An object containing the dynamic properties bindings.
         */
        dynamicProps() {
            const bindings = {};

            if (!this.block?.properties) {
                return bindings;
            }

            for (const [propName, propData] of Object.entries(this.block.properties)) {
                switch (propName) {
                    case 'disabledState':
                        if (this.getValue(propData)) {
                            bindings['disable'] = true;
                        }
                        break;
                }
            }

            return bindings;
        },
    },


    methods: {

        /**
         * Get classes
         * @param include
         * @param exclude
         * @return {string}
         */
        getClasses(include = false, exclude = false) {
            const resArr = []
            const props = Object.assign({}, this.block.properties, this.dynamicPanelStyles);

            // Properties
            if (this.block.properties) for (const [propName, propData] of Object.entries(props)) {
                if (propData !== '') if ((!exclude || !exclude[propName]) && (!include || include[propName])) switch (propName) {
                    case "textStyle":
                        resArr.push(`dg-text-${propData}`)
                        break;
                    case "textColor":
                        resArr.push(`dg-foreground-${propData}`)
                        break;
                    case "backgroundColor":
                        resArr.push(`dg-background-${propData}`)
                        break;
                    case "size":
                        resArr.push(`dg-size-${propData}`)
                        break;
                    case "padding":
                        propData?.left && resArr.push(`dg-pl-${propData.left}`);
                        propData?.top && resArr.push(`dg-pt-${propData.top}`);
                        propData?.right && resArr.push(`dg-pr-${propData.right}`);
                        propData?.bottom && resArr.push(`dg-pb-${propData.bottom}`);
                        break;
                    case "margin":
                        propData?.left && resArr.push(`dg-ml-${propData.left}`);
                        propData?.top && resArr.push(`dg-mt-${propData.top}`);
                        propData?.right && resArr.push(`dg-mr-${propData.right}`);
                        propData?.bottom && resArr.push(`dg-mb-${propData.bottom}`);
                        break;
                    case "gutter":
                        propData?.x && resArr.push(`dg-gutter-x-${propData.x}`);
                        propData?.y && resArr.push(`dg-gutter-y-${propData.y}`);
                        break;
                    case "extended":
                        if (propData) resArr.push(`dg-extended`)
                        break;
                    case "ellipsis":
                        if(propData) resArr.push(`dg-ellipsis-${this.block.properties?.ellipsis || '1'}`)
                        break;
                    case "sticky":
                        if (propData) resArr.push(`dg-sticky-${this.block.properties?.stickyPosition || 'top'}`)
                        break;
                    case "shadow":
                        resArr.push(`dg-shadow-${propData}`)
                        break;
                    case "textWeight":
                        resArr.push(`dg-text-weight-${propData}`)
                        break;
                    case "border":
                        if (propData.radiusTopLeft) resArr.push(`dg-radius-tl-${propData.radiusTopLeft}`)
                        if (propData.radiusTopRight) resArr.push(`dg-radius-tr-${propData.radiusTopRight}`)
                        if (propData.radiusBottomLeft) resArr.push(`dg-radius-bl-${propData.radiusBottomLeft}`)
                        if (propData.radiusBottomRight) resArr.push(`dg-radius-br-${propData.radiusBottomRight}`)
                        break;
                    case "contentAlign":
                        resArr.push(`dg-justify-${propData}`)
                        break;
                    case "itemsAlign":
                        resArr.push(`dg-items-${propData}`)
                        break;
                    case "flexWrap":
                        resArr.push(`dg-wrap-${propData}`)
                        break;
                }
            }

            // Return string
            return resArr.join(" ")
        },

        /**
         * This function is used to get the metadata of a variable.
         * It checks the source of the variable and constructs a key based on the source.
         * The key is then used to retrieve the metadata of the variable from the renderer.
         *
         * @param {Object} variable - The variable for which the metadata is to be retrieved.
         * @returns {Object|undefined} - Returns the metadata of the variable if found, else returns undefined.
         */
        getVarMeta(variable) {
            return this.storageValueManager.getVarMeta(variable?.nodeId);
        },

        /**
         * Get asset value
         * @param asset
         * @return {*|boolean|{}}
         */
        getAssetValue(asset) {
            // Get path
            const path = this.renderer.a2u.assetPath(this.getValue(asset))

            // Return path
            return path ? path : require("../assets/plugs/default-image.png");
        },

        /**
         * Get value variable
         * @param variable
         * @param toVariable
         * @param input
         */
        getValue(variable, toVariable, input = undefined) {
            return this.storageValueManager.getValue(variable, toVariable, input);
        },

        /**
         * Get variable value
         * @param path
         * @param defVal
         * @return {*}
         */
        getVariableValue(path, defVal) {
            return this.getValue({valueType: 'variable', value: path}, undefined, defVal)
        },

        /**
         * Set variable value
         * @param path
         * @param val
         * @return {*}
         */
        setVariableValue(path, val) {
            return this.setValue({valueType: 'variable', value: path}, val)
        },

        /**
         * Return storage
         */
        getStorage(name) {
            return this.dataContext[name]?.value;
        },

        /**
         * Compare values
         * @param leftOp
         * @param rightOp
         * @param comparator
         * @return {boolean}
         */
        compareValues(leftOp, rightOp, comparator) {
            switch (comparator) {
                case "==":
                    return leftOp == rightOp;
                default:
                    throw 'Unknown comparator ' + comparator
            }
        },

        /**
         * Interpret dynamic string
         */
        interpretString(params, input) {
            return this.storageValueManager.interpretString(params, input);
        },

        /**
         * Hook that is called before setting the operation value.
         * This method is intended to be implemented in child components.
         *
         * @param {Object} variableData - The data of the variable.
         * @param {string} operation - The operation to be performed.
         * @param {*} value - The value to be set.
         */
        // eslint-disable-next-line no-unused-vars
        beforeSetOperationValue(variableData, operation, value) {
            // implement in child component
        },

        /**
         * Set variable value
         * @param variableData
         * @param operation
         * @param value
         * @return {string}
         */
        setOperationValue(variableData, operation, value) {
            this.beforeSetOperationValue(variableData, operation, value);

            this.storageValueManager.setOperationValue(variableData, operation, value);
        },

        /**
         * Hook that is called before setting the value of a variable.
         * This method is intended to be implemented in child components.
         *
         * @param {Object} variableData - The data of the variable.
         * @param {*} value - The value to be set.
         */
        // eslint-disable-next-line no-unused-vars
        beforeSetValue(variableData, value) {
            // implement in child component
        },


        /**
         * Set variable value
         * @param variableData
         * @param value
         * @return {boolean}
         */
        setValue(variableData, value) {
            this.beforeSetValue(variableData, value);

            return this.storageValueManager.setValue(variableData, value);
        },

        /**
         * Retrieves the argument definitions for a given block ID.
         * This function checks the type of the block and constructs a storage block ID
         * based on the block type. It then filters the storage metadata to find the
         * relevant argument definitions.
         *
         * @param {string} blockId - The ID of the block for which to retrieve argument definitions.
         * @returns {Object|null} An object containing the argument definitions, or null if not found.
         */
        getArgumentsDefinition(blockId) {
            if (!blockId) {
                return null;
            }

            const block = this.renderer.a2u.blocks?.[blockId]?.node || undefined;

            if (!block) {
                return null;
            }

            let storageBlockId = null;

            if ((block?.type || '').startsWith('Widget:')) {
                storageBlockId = `diagram-${block.type.split(':')[1]}`;
            } else if (block.type === 'DiagramComponent' && block?.properties?.diagramComponentId) {
                storageBlockId = `diagram-${block.properties.diagramComponentId}`;
            } else if (block.type === 'CodeFunction' && block?.properties?.function) {
                storageBlockId = `func-args-${block.properties.function}`;
            }

            if (!storageBlockId) {
                return null;
            }

            const varMeta = Object.values(this.renderer.a2u.source.storage.meta)
              .filter(({blockId}) => blockId === storageBlockId)
              .map((node) => [node.name, node]);

            return Object.fromEntries(varMeta);
        },

        /**
         * Get arguments
         */
        getArguments(blockId, input, customArguments) {

            // Params
            const params = {}

            // Get arguments
            let pArgs = customArguments ? customArguments : this.renderer.a2u.blocks[blockId]?.node?.properties?.arguments

            // Check if arguments is Array - convert to map
            if(Array.isArray(pArgs)) {
                const newArgs = {}
                for(const a of pArgs) newArgs[a.name] = a.value
                pArgs = newArgs
            }

            // Get arguments definitions
            const argsDefinitions = this.getArgumentsDefinition(blockId);

            // Prepare params if set
            for (const [name, val] of Object.entries(pArgs || {})) {
                // Get source node meta
                //const varMeta = this.renderer.a2u.getVarMeta(val?.sourceVarId) || {}

                // Check if argument is defined
                if (argsDefinitions && !argsDefinitions[name]) {
                    continue;
                }

                // Fix value type
                if (argsDefinitions && val?.type !== argsDefinitions[name]?.type) {
                    val.type = argsDefinitions[name].type;
                }

                // Set param
                params[name] = this.getValue(val, undefined, input);

                const isReference = argsDefinitions?.[name] ? argsDefinitions?.[name]?.isReference : val?.isReference;

                // Set reference param
                if (isReference) {
                    params[`_${name}`] = val
                }
            }

            // Return params
            return params;
        },


        /**
         * Execute content in the instance
         * @param content
         * @param defValue
         * @return {*|undefined}
         */
        execContent(content, defValue) {

            console.error('exec content is depricated', content, defValue)

            // Return initial content
            return content
        },

        /**
         * Check for bind data and return
         * @param source
         * @param name
         * @param defValue
         */
        getProperty(source, name) {

            // Check for bind field
            if (source["bind_" + name]) {
                return this.execContent(source["bind_" + name], source[name])
            }

            // Return value
            return source[name]
        },

        /**
         * Apply animation according to frame
         */
        applyAnimation() {

            // No animation
            if (!Array.isArray(this.block.properties.animation)) return []

            // Get animation
            const animations = this.block.properties.animation

            // Result styles array
            const transforms = [], styles = {}

// Find first frame
            let fromFrameIndex = -1, cnt = 0

// Find nearest frame
            for (const fr of animations) {
                if (fromFrameIndex >= 0 && this.animation_frame < fr.startTime) break
                if (this.animation_frame >= fr.startTime) fromFrameIndex = cnt
                cnt++
            }

// Get frames
            const fromFrame = animations[fromFrameIndex]
            const toFrame = animations[fromFrameIndex + 1]

// If frame found
            if (fromFrame) for (const attr of Object.keys(fromFrame)) {
                const fromVal = parseFloat(fromFrame[attr]),
                    toVal = parseFloat(fromVal && toFrame && toFrame[attr] !== undefined ? toFrame[attr] : fromVal),
                    interpolation = parseFloat(toFrame ? ((this.animation_frame - fromFrame.startTime) / (toFrame.startTime - fromFrame.startTime)) : 1),
                    value = fromVal + (toVal - fromVal) * interpolation

                // Skip undefined values
                if (fromVal === undefined || toVal === undefined || isNaN(fromVal) || isNaN(toVal)) continue

                //if(attr == 'opacity') console.log('anim', fromVal, value, toVal, this.animation_frame, fromFrame, toFrame)

                // Apply transformations
                switch (attr) {

                    // Regular props
                    case "width":
                        styles['width'] = `${value}%`
                        break;
                    case "height":
                        styles['height'] = `${value}%`
                        break;
                    case "opacity":
                        styles['opacity'] = `${value}%`
                        break


                    // Transforms
                    case "positionLeft":
                        transforms.push(`translateX(${value}px)`)
                        break
                    case "positionTop":
                        transforms.push(`translateY(${value}px)`)
                        break
                    case "rotation":
                        transforms.push(`rotate(${value}deg)`)
                        break
                    case "scale":
                        transforms.push(`scale(${value / 100})`)
                        break
                    default:
                        break
                }
            }

// Return list
            return Object.assign(styles, {'transform': transforms.join(" ")})
        },

        /**
         * Process key presses
         * @param event
         */
        processKeyPresses(event) {

            // Construct key sequence
            let keySeq = event.ctrlKey ? "ctrl-" : "";

            // Alt key
            keySeq += event.altKey ? "alt-" : "";

            // Meta key
            keySeq += event.metaKey ? "meta-" : "";

            // Shift
            keySeq += event.shiftKey ? "shift-" : "";

            // Add key
            keySeq += event.key.toLowerCase()

            // Check if listener is set
            if(this.block.properties?.keypress?.find(e => e.name === keySeq)) {
                // Process outgoing links
                this.parentDiagram.processOutgoingLinks(this, this.block.id, undefined, 'press-'+keySeq)
            }
        },

        /**
         * Getting a current diagram path as a list of parent ids
         * @return {*}
         */
        getAppPath() {

            // Get parent
            let parent = this.parentDiagram;

            // Add current block id to path
            const path = [this.block.id]

            // Iterate on parents until exists
            while (parent?.block?.id) {
                path.push(parent.block.id)
                parent = parent.parentDiagram
            }

            // Concatenate path by '.'
            return path.reverse().join('.')
        },

        /**
         * Logs an error using the application's error logger.
         *
         * @param {Error} error - The error object to be logged.
         */
        logError(error) {
            try {
                this.renderer.a2u.errorLogger.log(error)
            } catch (e) {
                console.error('Failed to log error', e);
            }
        }
    }
}
