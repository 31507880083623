<template>
  <atu-renderer
    v-if="source && runMode !== 'internal-debug-mode'"
    :db-config="db"
    :functions="methodsList"
    :run-mode="runMode"
    :db-server-url="dbServerUrl"
    :startEvent="startEvent"
    :source="source"
    :storage="storage"
    :native-components="componentsList"
    platform="web"
    :module-id="moduleId"
    :plugins="plugins"
    :debug="10"
    :designer-components-list="designerComponentsList"
    :on-init="initApp"
  />
</template>

<script>
import {markRaw} from 'vue';
import { decode as msgpackDecode } from '@msgpack/msgpack';
import AtuRenderer from "@/renderer/AtuRenderer";
import {componentsList} from "./componentsList";
import db from "./db";
import {methodsList} from "./methodsList";
import "@/../../common/assets/fonts/fonts.css";
import {ThreePlugin} from './ThreePlugin';
import {designerComponentsList} from '@/renderer/components/designerComponentsList';
import {AppParser} from '@/renderer/utils/appParser';
import {SqlExecutorService} from './services/SqlExecutorService';

export default {
  name: 'TestApp',

  setup() {
    return {
      designerComponentsList,
    }
  },

  computed: {
    methodsList() {
      return methodsList
    },
    db() {
      return db
    },
    componentsList() {
      return componentsList
    }
  },
  components: {
    AtuRenderer
  },
  data: () => ({
    source: false,
    moduleId: false,
    storage: false,
    startEvent: "start",
    runMode: "debug",
    dbServerUrl: "",
    plugins: {
      ThreePlugin: ThreePlugin,
    },
    a2u: null,
    screenMode: 'mobile-portrait',
  }),

  methods: {
    initApp(a2u) {
      this.$watch('screenMode', () => {
        a2u.storage.set("settings.platform.screenMode", this.screenMode);
      });

      // Detect screen mode
      this.detectScreenMode();
    },

    /**
     * Detects the screen mode (desktop or mobile) and updates the `screenMode` data property accordingly.
     * Adds an event listener to update the screen mode when the orientation changes.
     */
    detectScreenMode() {
      try {
        if (this.$q.platform.is.desktop) {
          this.screenMode = 'desktop';

          return;
        }

        const mq = window.matchMedia("(orientation: portrait)");

        this.screenMode = `mobile-${mq.matches ? 'portrait' : 'landscape'}`;

        mq.addEventListener('change', (e) => {
          this.screenMode = `mobile-${e.matches ? 'portrait' : 'landscape'}`;
        });
      } catch (e) {
        console.error('Failed to detect screen orientation:', e);
      }
    },
  },

  async created() {

    // Get segments
    const [, mId, mode] = window.location.pathname.split('/')

    // Reset data if mode is reset
    if (mode === "reset") {
      localStorage.clear()
      sessionStorage.clear()

      // Reload page with "debug" mode
      location.href = `/${mId}/debug`
    }

    // Store run mode
    this.runMode = mode || "debug"

    // Set db url
    this.dbServerUrl = process.env.VUE_APP_DB_URL

    // Get appId from hash
    this.moduleId = parseInt(mId);

    // Start event
    this.startEvent = "start"

    // Load source from server
    if (this.moduleId) {
      this.app.client.call('app', 'source', this.moduleId, 'debug', false).then(async (appSource) => {
        // Get source
        this.source = markRaw(appSource?.data ? msgpackDecode(new Uint8Array(appSource?.data)) : {});

        // Load app
        if (this.runMode === 'internal-debug-mode') {
          this.a2u = markRaw(new AppParser({
            context: this,
            dbServerUrl: this.dbServerUrl,
            dbConfig: this.dbConfig,
            runMode: this.runMode,
            nativeComponents: [],
            designerComponentsList: [],
            functions: [],
            plugins: this.plugins,
            platform: this.platform,
            moduleId: this.moduleId,
            onInit: async (a2u) => {
              const service = new SqlExecutorService({a2u});

              service.init();
            },
          })).setSource(this.source);

          await this.a2u.parseApp();
        }
      });
    }
  },
}
</script>
