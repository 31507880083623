<template>

  <ab-flow-base-cmp :draggable="false" class="in-app-list" :block="block" :is_container="true">
    <div v-for="(item, k) of items" :key="k"  @click="selectProduct(item)">
      <data-provider :storage-key="block?.title" :data="new AbStorage(prepareStorageData(k, item))">
        <atu-components-renderer :items="block.children"/>
      </data-provider>
    </div>
  </ab-flow-base-cmp>

</template>

<script>
import {reactive} from 'vue';
import humanizeDuration from 'humanize-duration';
import {AbStorage} from "a2u-renderer-common/src/utils/abStorage";
import {renderMixins} from "../../components/renderMixins";
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp";
import DataProvider from "../../components/DataViews/DataProvider/DataProvider.vue";
import AtuComponentsRenderer from "../../AtuComponentsRenderer.vue";

// Default translations
const defaultTranslations = {
  'sub.regular.ctaTitle': 'Subscribe',
  'sub.regular.subscriptionDetails': `Subscribe for {priceTitle} per {billingPeriodTitle}. Recurring billing every {billingPeriodTitle}. Cancel anytime in {storeName}`,
  'sub.regular.subscriptionRules': `Subscriptions are billed every {billingPeriodTitle} at the specified price. The payment is made through the {storeName}. You can cancel your subscription at any time in the {storeName} settings. Your subscription will be automatically renewed for a similar period unless canceled at least 24 hours before the end of the applicable period.`,

  'sub.trial.ctaTitle': `Try free trial for {trialPeriodTitle}`,
  'sub.trial.subscriptionRules': `With taking out the subscription, you have {trialPeriodTitle} to try a premium features for free. After that, {priceTitle}/{billingPeriodTitle} is automatically deducted unless the subscription is canceled at least 24 hours before the end of the trial period. You can cancel your subscription at any time in the {storeName} settings. Subscriptions are billed annually at the specified price. The payment is made through the {storeName}.`,
  'sub.trial.trialDescription': `{trialPeriodTitle} free trial, then {priceTitle}/{billingPeriodTitle}`,
  'sub.trial.subscriptionDetails': `{trialPeriodTitle} free trial, then {priceTitle} per {billingPeriodTitle}. Recurring billing every year. Cancel anytime in {storeName}`,

  'consumable.ctaTitle': 'Buy',
  'consumable.subscriptionDetails': `Payment of {priceTitle} for {title}`,
  'consumable.subscriptionRules': `Payment for {title} at the specified price. The payment is made through the {storeName}`,

  // Web
  'web.sub.regular.ctaTitle': 'Subscribe',
  'web.sub.regular.subscriptionDetails': `Subscribe for {priceTitle} per {billingPeriodTitle}. Recurring billing every {billingPeriodTitle}. Cancel anytime by following the instructions in your email.`,
  'web.sub.regular.subscriptionRules': `Your subscription will be automatically renewed for a similar period unless canceled at least 24 hours before the end of the applicable period.`,

  'web.sub.trial.ctaTitle': `Try free trial for {trialPeriodTitle}`,
  'web.sub.trial.subscriptionRules': `With taking out the subscription, you have {trialPeriodTitle} to try a premium features for free. After that, {priceTitle}/{billingPeriodTitle} is automatically deducted unless the subscription is canceled at least 24 hours before the end of the trial period. You can cancel your subscription at any time by following the instructions in your email.`,
  'web.sub.trial.trialDescription': `{trialPeriodTitle} free trial, then {priceTitle}/{billingPeriodTitle}`,
  'web.sub.trial.subscriptionDetails': `{trialPeriodTitle} free trial, then {priceTitle} per {billingPeriodTitle}.`,

  'web.consumable.ctaTitle': 'Buy',
  'web.consumable.subscriptionDetails': `Payment of {priceTitle} for {title}`,
  'web.consumable.subscriptionRules': `Payment for {title} at the specified price.`,
};

export default {
  components: {AtuComponentsRenderer, DataProvider, AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "InAppProductsListEditorCmp",
  data: () => ({
    currentProduct: false
  }),

  created() {
    // Watch for products and set current product as first from list
    this.$watch("items", (items) => {
      // If single product
      if (items.length === 1) {
        if (this.autoSelect) {
          this.selectProduct(items[0]);
        }

        return;
      }

      if(!this.currentProduct && items.length) {
        // Find trial offer to select
        for(const pr of items) if(pr.trialPeriod) {
          this.selectProduct(pr)
          break;
        }

        // Select first product if no trial offer
        if(!this.currentProduct) this.selectProduct(items[0])
      }
    }, {deep: true});
  },

  methods: {


    /**
     * Unpack period
     * @param period
     */
    unpackPeriod(period) {

      // No period
      if(!period) return undefined;

      // Extract the number (X) from the input
      const amount = parseInt(period.substring(1, period.length - 1), 10);

      let unit = String(period[period.length - 1]).toLowerCase();

      if (unit === 'm') {
        unit = 'mo';
      }

      if (!['d', 'w', 'mo', 'y'].includes(unit)) {
        throw new Error('Invalid period format');
      }

      return humanizeDuration(amount, {
        largest: 1,
        language: this.renderer?.currentLanguage,
        fallbacks: ['en'],
        units: [unit],
        unitMeasures: {
          y: 1,
          mo: 1,
          w: 1,
          d: 1,
          h: 1,
          m: 1,
          s: 1,
          ms: 1
        }
      });
    },

    /**
     * Localizes a product parameter based on the provided alias and replacement data.
     *
     * @param {string} alias - The alias of the product parameter to localize.
     * @param {Object} [replaceData={}] - An optional object containing key-value pairs for replacing placeholders in the localized string.
     * @param {string} [value=undefined] - An optional value to use as a fallback if the localized string is not found.
     * @returns {string} The localized string with placeholders replaced by the provided data.
     */
    localizeProductParam(alias, replaceData = {}, value = undefined) {
      // Get the module ID
      const moduleId = this.renderer?.moduleId;

      // Get the platform prefix
      const platformPrefix = this.targetPlatform === 'web' ? 'web.' : '';

      // Get the default value
      const defaultValue = value || defaultTranslations[`${platformPrefix}${alias}`] || '';

      // Interpret the string
      let interpretedString = this.interpretString({
        isLocalizable: true,
        localeAlias: `${moduleId}.iap.${platformPrefix}${alias}`,
        value: defaultTranslations[`${platformPrefix}${alias}`],
      });

      // If the interpreted string is not a string, use the default value
      if (typeof interpretedString !== 'string') {
        interpretedString = defaultValue;
      }

      // Replace the placeholders in the interpreted string
      if (Object.keys(replaceData).length) {
        for (const key in replaceData) {
          const regex = new RegExp(`\\{${key}\\}`, 'g');

          interpretedString = interpretedString.replace(regex, replaceData[key]);
        }
      }

      return interpretedString;
    },

    /**
     * Prepare product
     * @param productInfo
     */
    prepareProduct(productInfo) {

      // Create copy
      const product = Object.assign({}, productInfo)

      // Store name
      const storeName = this.renderer?.a2u?.getPlatform?.() === "ios" ? "App Store" : "Google Play Store";

      product.title = this.localizeProductParam(`title.${product.id}`, {}, product.title);

      // Set CTA title
      switch (product.type) {
        case "subscription":
          product.billingPeriodTitle = this.unpackPeriod(product.billingPeriod)
          product.trialPeriodTitle = this.unpackPeriod(product.trialPeriod)

          product.subscriptionDetails = ''
          if (product.trialPeriod) {
            product.ctaTitle = this.localizeProductParam('sub.trial.ctaTitle', {
              trialPeriodTitle: product.trialPeriodTitle,
            });

            product.subscriptionRules = this.localizeProductParam('sub.trial.subscriptionRules', {
              trialPeriodTitle: product.trialPeriodTitle,
              priceTitle: product.priceTitle,
              billingPeriodTitle: product.billingPeriodTitle,
              storeName,
            });

            product.trialDescription = this.localizeProductParam('sub.trial.trialDescription', {
              trialPeriodTitle: product.trialPeriodTitle,
              priceTitle: product.priceTitle,
              billingPeriodTitle: product.billingPeriodTitle,
            });

            product.subscriptionDetails = this.localizeProductParam('sub.trial.subscriptionDetails', {
              trialPeriodTitle: product.trialPeriodTitle,
              priceTitle: product.priceTitle,
              billingPeriodTitle: product.billingPeriodTitle,
              storeName,
            });
          } else {
            product.ctaTitle = this.localizeProductParam('sub.regular.ctaTitle');

            product.subscriptionDetails = this.localizeProductParam('sub.regular.subscriptionDetails', {
              priceTitle: product.priceTitle,
              billingPeriodTitle: product.billingPeriodTitle,
              storeName,
            });

            product.subscriptionRules = this.localizeProductParam('sub.regular.subscriptionRules', {
              billingPeriodTitle: product.billingPeriodTitle,
              storeName,
            });

            product.trialDescription = ``
          }
          break;

        case "consumable":
        case "product":
          product.ctaTitle = this.localizeProductParam('consumable.ctaTitle');

          product.subscriptionDetails = this.localizeProductParam('consumable.subscriptionDetails', {
            priceTitle: product.priceTitle,
            title: product.title,
          });

          product.subscriptionRules = this.localizeProductParam('consumable.subscriptionRules', {
            title: product.title,
            storeName,
          });
          break;
      }

      // Return product
      return product;

    },

    /**
     * Get mock products list
     */
    getMockProducts() {
      const products = {};

      // Prod list
      const prodList = this.renderer.source?.iap?.products || [];

      // Generate products
      if (!prodList.length) for (let n = 1; n <= 3; n++) {
        products[n] = {
          title: `Product ${n} title`,
          productId: n,
        }
      }

      // Generate products
      let i = 1;
      for (const product of prodList || []) {
        products[product.productId] = {
          title: product.title,
          id: product.productId,
          description: `Product description`,
          priceTitle: i + '.00$',
          priceValue: i + '.00',
          billingPeriod: 'P1Y',
          trialPeriod: i === 1 ? 'P1W' : false,
          type: 'subscription'
        }
        i++;
      }

      // Return products
      return products;
    },

    /**
     * Select product
     * @param pr
     */
    selectProduct(pr) {
      try {
        if (!pr?.id) {
          this.logError(new Error(`Product not selected: ${pr?.type || 'n/a'} / ${pr?.title || 'n/a'}. Block: #${this.block?.id}`));
        }

        this.currentProduct = pr
        this.setOperationValue(this.block.properties?.productVariable, "set", {valueType: "static", value: pr})
      } catch (e) {
        this.logError(new Error(`Product not selected (exception): ${pr?.type || 'n/a'} / ${pr?.title || 'n/a'}. Block: #${this.block?.id}`));
      }
    },

    /**
     * Prepares the data for storage.
     *
     * This method is responsible for preparing the data that will be stored.
     * It creates an object with two properties: `initialStorage` and `storageCreator`.
     *
     * @param {Number} idx - The index of the item in the list.
     * @param {Object} item - The item object that will be stored.
     * @returns {Object} The prepared data for storage. It includes:
     * - `initialStorage`: an object that contains the item and its index as properties.
     * - `storageCreator`: a function that takes data as a parameter and returns a reactive version of the data.
     */
    prepareStorageData(idx, item) {
      return {
        initialStorage: {item: Object.assign({number: idx+1}, item)},
        storageCreator: (data) => reactive(data),
      };
    },
  },

  computed: {
    AbStorage() {
      return AbStorage
    },

    /**
     * Filtered product id
     * @return {*}
     */
    filteredProductId() {
      return this.getValue(this.block.properties?.productId)
    },

    /**
     * Auto select product
     * @return {*}
     */
    autoSelect() {
      return this.getValue(this.block.properties?.autoSelect) || false;
    },


    /**
     * Products list
     * @return {*}
     */
    items() {
      return this.wait("items", async () => {

        try {

          // Get ad instance
          const iapManager = this.renderer.a2u.getDevice()?.getPluginInstance?.("IAPManager");

          // Get list of products
          let products = (!iapManager ? this.getMockProducts() : (await iapManager.getProductList(30)) || {});

          // Filter list by product id
          if (this.filteredProductId) {
            return this.filteredProductId.split(',')
              .filter((productId) => products[productId]?.id)
              .map((productId) => this.prepareProduct(products[productId]));
          }

          // Get prepared product list
          let list = Object.values(products)
            .filter(({id}) => id)
            .map(this.prepareProduct)

          // Order list by price
          list = list.sort((a, b) => {
            const priceA = parseFloat(a.priceValue)
            const priceB = parseFloat(b.priceValue)
            if (priceA < priceB) return -1;
            if (priceA > priceB) return 1;
            return 0;
          })

          // Mock products list
          return list;

        } catch (e) {
          console.error("IAP products error", e)

          // Log error
          this.logError(e);
        }
      }, [])
    },

    targetPlatform() {
      return this.renderer.a2u.source.modules[this.renderer.a2u.getModuleId()]?.type === 'web' ? 'web' : 'mobile';
    },
  }
}

</script>


<style lang="scss">

.in-app-list {
  flex-direction: inherit;
  flex-wrap: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: 100%;
  position: relative;
}

</style>
