import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class MailingListManagerProcessor extends BaseComponentProcessor {
    /**
     * Process incoming event
     */
    async processEvent() {
        // Get the endpoint URL
        const endpointUrl = this._getEndpoint();

        // If the endpoint URL is not set, return an error
        if (!endpointUrl) {
            return this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }

        // Get the block properties
        const emails = [
          ...new Set((this.context.interpretString(this.block?.properties?.email) || '').split(',').map((email) => email.trim()).filter(Boolean))
        ];
        const listIds = [
          ...new Set((this.context.interpretString(this.block?.properties?.list_id) || '').split(',').map((list_id) => list_id.trim()).filter(Boolean))
        ];
        const action = this.block?.properties?.action === 'remove' ? 'removeFromMailingList' : 'addToMailingList';

        // If any of the block properties are not set, return an error
        if (!emails?.length || !listIds?.length) {
            const notFilledProperties = Object.entries({
                email: !!emails?.length,
                list_id: !!listIds?.length,
            }).filter(([, value]) => !value).map(([key]) => key).join(', ');

            this.context.renderer.a2u.debugLogger.log({
                type: 'error',
                message: `Missing properties: ${notFilledProperties}`,
                data: JSON.parse(JSON.stringify({
                    processNum: this.processNum,
                    diagram: { id: this.context?.currentDiagram?.block?.properties?.diagramComponentId },
                    component: {
                        id: this.block?.id,
                        properties: this.context.renderer.a2u.patchComponentProperties(this, this.block?.properties || {}),
                    },
                    data: {},
                })),
            });

            return this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }

        // Get the custom fields
        let customFields = undefined;
        if (this.block?.properties?.customFields && action === 'addToMailingList') {
            customFields = Object.fromEntries(
              (this.block?.properties?.customFields?.items || [])
                .map((item) => [item.field, this.context.interpretString(item.value)])
                .filter(([field, value]) => field && value)
            );
        }

        try {
            const payload = {
                emails,
                listIds,
            };

            // Add the custom fields to the payload
            if (Object.keys(customFields || {}).length) {
                payload.customFields = customFields;
            }

            // Send the request to the endpoint
            await this.context?.app?.client?.getAxios().post(`${endpointUrl}/sendgrid/${action}`, [payload], {
                headers: {
                    'x-api-key': process.env.VUE_APP_A2U_ACCESS_TOKEN,
                },
            });

            return this.processOutgoingLinks(this.context, this.block.id, null, 'success');
        } catch (e) {
            console.error(e);

            const {error, code} = e?.response?.data?.exception || {};

            if (error && code === 'unknown_custom_fields') {
                this.logError(new Error(e.response.data.exception.error));

                this.context.renderer.a2u.debugLogger.log({
                    type: 'error',
                    message: error,
                    data: JSON.parse(JSON.stringify({
                        processNum: this.processNum,
                        diagram: { id: this.context?.currentDiagram?.block?.properties?.diagramComponentId },
                        component: {
                            id: this.block?.id,
                            properties: this.context.renderer.a2u.patchComponentProperties(this, this.block?.properties || {}),
                        },
                        data: {
                            customFields,
                        },
                    })),
                });
            }

            return this.processOutgoingLinks(this.context, this.block.id, error || null, 'error');
        }
    }

    /**
     * Retrieves the endpoint URL from the module configuration.
     *
     * This method first retrieves all modules from the context. It then finds the module
     * that is of type 'server' and has the 'sendGridEnable' property set.
     *
     * If such a module is found, its 'endpointUrl' property is returned. If no such module
     * is found, the method falls back to returning the 'VUE_APP_A2U_URL' environment variable.
     *
     * @returns {string} The endpoint URL.
     */
    _getEndpoint() {
        const module = Object.values(this.context?.renderer?.a2u?.source?.modules || {})
          .find((m) => m.type === 'server' && m?.sendGridEnable);

        return module?.endpointUrl || process.env.VUE_APP_A2U_URL;
    }
}
