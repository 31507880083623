/**
 * SqlExecutorService class handles SQL execution and communication with the parent window.
 */
export class SqlExecutorService {
    /**
     * Constructor for SqlExecutorService.
     * @param {Object} config - Configuration object.
     * @param {Object} config.a2u - An instance providing database access.
     */
    constructor({a2u}) {
        this.a2u = a2u;
    }

    /**
     * Initializes the service by setting up the message event listener and firing the ready event.
     */
    init() {
        window.addEventListener('message', (event) => {
            const { eventType, data } = event.data;

            switch (eventType) {
                case 'check-ready':
                    this.fireReadyEvent();
                    break;
                case 'sql-execute':
                    this.executeSql(data);
                    break;
            }
        });

        this.fireReadyEvent();
    }

    /**
     * Fires a 'executor-ready' event to the parent window.
     */
    fireReadyEvent() {
        window.parent.postMessage({
            eventType: 'executor-ready'
        }, '*');
    }

    /**
     * Executes the provided SQL query and posts the result or error back to the parent window.
     * @param {Object} data - Data object containing the SQL query.
     * @param {string} data.query - The SQL query to be executed.
     */
    async executeSql({query, bindings = {}}) {
        try {
            const db = this.a2u.getDb();

            const params = [];

            query = query.replace(/{([^{}]*)}/g, (_, key) => {
                const parVal = bindings[key];

                if (parVal === undefined) {
                    throw new Error(`Query ${query} error: no value for binding ${key}`);
                }

                params.push(...(Array.isArray(parVal) ? parVal : [parVal]))

                return Array.isArray(parVal) ? parVal.map(() => '?').join(',') : '?';
            });

            const result = await db.rawQuery(query, params);

            window.parent.postMessage({
                eventType: 'sql-executed:result',
                data: {
                    result,
                },
            }, '*');
        } catch (e) {
            window.parent.postMessage({
                eventType: 'sql-executed:error',
                error: e,
            }, '*');
        }
    }
}
