<template>

  <transition
    :name="`q-transition--${transition.name}`"
    :css="!parentTeleport"
    @before-enter="transitionBeforeEnter"
    @after-enter="transitionAfterEnter"
    :appear="!parentWidget && shouldTransition"
    :duration="transition.duration"
  >
    <div
        v-if="currentPageDiagram || currentPage"
        class="diagram-holder"
        :class="{
          absolute: transition.active,
        }"
        :key="transition.key"
    >
      <diagram-component-editor-cmp
          v-if="currentPageDiagram"
          ref="diagram"
          :incoming-params="diagramIncomingParams"
          :block="currentPageDiagram"
          :key="currentPageDiagramKey"
          :start-event="childDiagramStartEvent"
          @fragment-shown="diagramShownFragment"
          @exit-diagram="diagramExited"
      />

      <fragment-editor-cmp v-if="currentPage" :block="currentPage" :ref="currentPage.id" :key="currentPageKey"/>
    </div>
  </transition>

  <template v-for="(mod) in modals" :key="mod.blockId">
    <q-dialog
        class="fragment-modal"
        :style="getModalStyles(mod)"
        @show="modalShown(mod.blockId)"
        :model-value="true"
        :ref="mod.blockId"
        :persistent="mod?.params?.isPersistent"
        @hide="removeFromModals(mod.blockId)"
        :class='`modal-${mod?.params?.type} mw-${mod?.params?.modalWidth} mh-${mod?.params?.modalHeight}`'
        :maximized="isMaximized(mod)"
        :position="getModalPosition(mod)"
        :seamless="mod?.params?.noBackdrop"
        v-bind="modalTransitionProps(mod)"
    >
      <component :is="getModalComponent(mod.blockId)" :incoming-params="mod?.params?.incomingParams" :ref="`modal-fr-${mod.blockId}`" :block="renderer.a2u.blocks[mod.blockId]?.node"/>
    </q-dialog>
  </template>

  <template v-for="(diagram, idx) in processDiagramsStack" :key="idx">
    <diagram-component-editor-cmp
        :incoming-params="diagramIncomingParams"
        :block="diagram"
        :start-event="childDiagramStartEvent"
        @exit-diagram="processDiagramExited(idx)"
    />
  </template>
</template>

<script>
import {onBeforeUnmount, ref, toRaw, getCurrentInstance} from 'vue';
import {nanoid} from 'nanoid';
import {AbOrmVueQueryProcessor} from "ab-application/src/utils/AbOrmVueQueryProcessor";
import {renderMixins} from "../../renderMixins";
import FragmentEditorCmp from "../../Containers/Fragment/FragmentEditorCmp.vue";
import DiagramComponentEditorCmp from "../../Logic/DiagramComponent/DiagramComponentEditorCmp.vue";
import DiagramComponentRenderer from "./DiagramComponentRenderer.vue";

export default {
  components: {DiagramComponentEditorCmp, FragmentEditorCmp, DiagramComponentRenderer},
  mixins: [renderMixins],
  emits: ['fragment-shown', 'exit-diagram'],
  props: {
    block: {},
    currentFragmentId: {},
    startEvent: {
      default: "start"
    },
    shouldTransition: {
      required: false,
      type: Boolean,
      default: true,
    },
  },

  inject: {
    parentWidget: {
      default: false,
    },
    parentTeleport: {
      default: false,
    },
  },

  provide() {
    return {
      parentDiagram: this
    }
  },
  name: "DiagramComponentRenderer",

  setup() {
    const instance = getCurrentInstance();

    const isActive = ref(false);

    onBeforeUnmount(() => {
      isActive.value = false;

      if (instance?.proxy?.renderer?.currentState) {
        // Get debug params
        instance.proxy.renderer.currentState[instance.proxy.getAppPath()] = false
      }
    });

    return {isActive};
  },

  data: () => ({
    modals: [],
    diagramObj: false,
    currentPage: false,
    currentPageDiagram: false,
    currentPageDiagramKey: 0,
    currentPageKey: 0,
    //childDiagramParams: {},
    diagramIncomingParams: false,
    childDiagramStartEvent: "start",
    isReady: false,
    eventHandlers: {},
    processDiagramsStack: {},
    transition: {
      key: 0,
      name: 'jump-up',
      duration: 300,
      active: false,
    },
  }),
  created() {

    // Load diagram
    this.diagramObj = this.renderer.a2u.diagrams[this.block?.properties?.diagramComponentId];

    // Subscribe to data
    //this.subscribeToData();

    // Set this diagram as current in a2u
    this.renderer.a2u.setCurrentDiagram(this)

    // Check if the current diagram has debug app state
    if (this.renderer?.currentState) {

      // Get debug params
      const params = this.renderer.currentState[this.getAppPath()];

      // If params are set, navigate to the diagram or fragment
      if (params) {
        this.currentPageDiagram = this.renderer.a2u.blocks[params.diagramId]?.node;
        this.currentPage = this.renderer.a2u.blocks[params.pageId]?.node;

        // Set current diagram storage
        if(params.diagramStorage && this.getStorage("diagram")) {
          this.getStorage("diagram").storage = params.diagramStorage;
        }

        // If diagram or fragment is present - don't run diagram
        // as usual and exit method
        if(this.currentPageDiagram || this.currentPage) {
          this.isActive = true;
          return;
        }
      }
    }

    // Run
    this.runDiagram(this.currentFragmentId);

    // Page is ready
    this.isReady = true;

    // Set as active
    this.isActive = true;
  },

  methods: {


    /**
     * Run diagram event going deeper to parents until successfully run
     * @param eventName
     */
    runDiagramParents(eventName) {
      if (!this.runDiagram(eventName)) {
        if (this.parentDiagram?.runDiagramParents) this.parentDiagram.runDiagramParents(eventName)
      }
    },

    /**
     * App resumed
     */
    restore() {
      // Run restore event
      this.runDiagramParents("restore")
    },

    /**
     * App paused
     */
    pause() {
      // Run pause event
      //this.runDiagramParents("pause")
    },

    /**
     * Modal shown
     * @param id
     */
    modalShown(id) {
      this.$nextTick(() => {
        this.$refs[`modal-fr-${id}`]?.[0].modalShown?.()
      })
    },

    /**
     * This method is used to generate transition properties for a modal.
     * It checks if the modal has specific transition properties defined, if not, it assigns default values.
     * The default transition effect is 'fade' and the default transition duration is 300ms.
     *
     * @param {Object} modal - The modal for which to generate transition properties.
     * @returns {Object} An object containing the transition properties for the modal.
     */
    modalTransitionProps(modal) {
      if (modal.params?.type !== 'modal') {
        return {};
      }

      return {
        transitionShow: modal.params?.transitionShow || 'jump-up',
        transitionHide: modal.params?.transitionHide || 'jump-down',
        transitionDuration: modal.params?.transitionDuration || 300,
      };
    },

    /**
     * Get modal position
     * @param mod
     */
    getModalPosition(mod) {
      return ['bottom', 'left', 'right', 'top'].includes(mod?.params?.position) ? mod?.params?.position : undefined
    },

    /**
     * Generates the styles for a modal.
     * @param {Object} mod - The modal object containing parameters.
     * @param {Object} mod.params - The parameters for the modal.
     * @param {boolean} [mod.params.noBackdrop] - Indicates if the modal should have no backdrop.
     * @param {string} [mod.params.overlayColor] - The overlay color for the modal. Defaults to 'rgba(0,0,0,0.4)' if not provided.
     * @returns {Object} An object containing the CSS variable for the modal overlay color.
     */
    getModalStyles(mod) {
      if (mod?.params?.noBackdrop) {
        return  {};
      }

      const overlayColor = mod?.params?.overlayColor || 'rgba(0,0,0,0.4)';

      return {
        '--fragment-modal-overlay-color': overlayColor,
      };
    },

    /**
     * Check if maximized
     * @param mod
     */
    isMaximized(mod) {
      return mod?.params?.position === 'full'
    },

    /**
     * Register component event handler
     * @param blockId
     * @param eventName
     * @param handler
     */
    registerHandler(blockId, eventName, handler) {
      //console.log('Register event', this.block, blockId, eventName)
      this.eventHandlers[`${blockId}-${eventName}`] = handler
      //console.log("registerHandler", this.eventHandlers)
    },

    /**
     * Unregister component event handler
     * @param blockId
     * @param eventName
     * @param handler
     */
    unregisterHandler(blockId, eventName) {
      //console.log('Unregister event', blockId, eventName)
      if (this.eventHandlers[`${blockId}-${eventName}`]) delete this.eventHandlers[`${blockId}-${eventName}`];
      //console.log("registerHandler", this.eventHandlers)
    },

    /**
     * Call handler
     * @param blockId
     * @param eventName
     * @param data
     */
    callHandler(blockId, eventName, data) {
      if (!this.eventHandlers[`${blockId}-${eventName}`]) throw `Handler ${blockId}-${eventName} not found`
      //console.log("callHandler", blockId, eventName, data, this.eventHandlers);
      return this.eventHandlers[`${blockId}-${eventName}`]?.(data)
    },


    /**
     * Subscribe to data
     */
    /*subscribeToData() {

      //console.log("sub", this.diagramObj?.source?.properties?.subscriptions);
      for (const sub of Object.values(this.diagramObj?.source?.properties?.subscriptions || {})) {

        // Prepare params
        const params = {};
        for (const [name, val] of Object.entries(sub.query?.bindings || {})) {
          params[name] = this.getValue(val)
        }

        // Subscribe for data
        this.renderer.a2u.dbs[sub.dbId].subscribe(sub.tableId, sub.subId, params)
      }
    },*/

    /**
     * Unsubscribe from data
     */
    unsubscribeFromData() {

      // No subscriptions in debug mode
      if (this.renderer.a2u.runMode === 'debug') {
        return;
      }

      for (const sub of Object.values(this.diagramObj?.source?.properties?.subscriptions || {})) {
        this.renderer.a2u.dbs[sub.dbId].unsubscribe(sub.tableId, sub.subId)
      }
    },

    /**
     * Show fragment as modal
     */
    showModal(blockId, params) {
      // Check if already in modals
      if (this.modals.find(m => m.blockId === blockId)) return;

      // Add to modals
      this.modals.push({
        blockId,
        params
      })
    },

    /**
     * Returns the appropriate component for a given block ID.
     * If the block type is 'DiagramComponent', it returns the DiagramComponentRenderer.
     * Otherwise, it returns the FragmentEditorCmp.
     *
     * @param {string} blockId - The ID of the block to get the component for.
     * @returns {Object} The component to be used for the given block ID.
     */
    getModalComponent(blockId) {
      const node = this.renderer.a2u.blocks[blockId]?.node;

      return node?.type === 'DiagramComponent' ? DiagramComponentEditorCmp : FragmentEditorCmp;
    },

    /**
     * Executes a process diagram by adding the block to the process diagrams stack.
     *
     * @param {Object} block - The block to be added to the process diagrams stack.
     */
    execProcessDiagram(block) {
      this.processDiagramsStack[nanoid(10)] = block;
    },

    /**
     * Handles the exit of a process diagram by removing it from the process diagrams stack.
     *
     * @param {string} id - The index of the process diagram to be removed from the stack.
     */
    processDiagramExited(id) {
      if (!this.processDiagramsStack[id]) {
        return;
      }

      delete this.processDiagramsStack[id];
    },

    /**
     * Exit diagram event
     */
    exitDiagram() {
      this.$emit('exit-diagram');
    },

    /**
     * Hide modal
     * @param blockId
     */
    hideModals(blockId) {
      for (const mod of this.modals) {
        if (!blockId || mod.blockId === blockId) {
          let dlg = this.$refs[mod.blockId]
          if (dlg && Array.isArray(dlg)) dlg = dlg[0]
          dlg?.hide?.()
        }
      }
    },

    /**
     * Remove block from modals
     * @param blockId
     */
    removeFromModals(blockId) {
      this.modals = blockId ? this.modals.filter(m => m.blockId !== blockId) : [];
    },

    /**
     * Run diagram
     */
    runDiagram(eventName) {

      //console.error("Run diagram", this.block.id, eventName);

      // Set default event
      if (!eventName) eventName = this.startEvent;

      // Find CustomEvent component`
      const eventBlock = this.diagramObj?.source?.children.find(c => c.type === 'CustomEvent' && c.properties?.name === eventName);

      // Check for start event
      if (!eventBlock) {
        console.error(`Event ${eventName} not found in diagram ${this.diagramObj?.title}`)
        return false;
      }

      // Run component
      this.processOutgoingLinks(this, eventBlock?.id, {});

      // All ok
      return true;
    },

    /**
     * Hide current page if diagram show fragment
     */
    diagramShownFragment() {
      if (!this.currentPageDiagram?.properties?.isEmbedded) this.currentPage = false
    },

    /**
     * Remove diagram from page if exited
     */
    diagramExited() {
      if (this.currentPageDiagram?.properties?.isEmbedded) this.currentPageDiagram = false;
    },

    /**
     * Defines the transition properties for a given link ID.
     * It sets the transition name and duration based on the properties of the link.
     * If the properties are not found or an error occurs, it defaults to 'jump-up' for the name and 300ms for the duration.
     *
     * @param {string} linkId - The ID of the link to define the transition for.
     */
    defineTransition(linkId) {
      try {
        const properties = this.renderer.a2u?.blocks?.[linkId]?.node?.properties;

        this.transition.name = properties?.transitionName || 'jump-up';
        this.transition.duration = parseInt(properties?.transitionDuration) || 300;
      } catch (e) {
        this.transition.name = 'jump-up';
        this.transition.duration = 300;
      }
    },

    /**
     * Navigate to block
     * @param blockId
     * @param params
     * @param event
     * @param linkId
     */
    navigate(blockId, params, event = "start", linkId = undefined) {
      this.defineTransition(linkId);

      // Check if debug state is presents
      if (this.renderer?.currentState) {
        this.renderer.currentState[this.getAppPath()] = Object.assign(this.renderer.currentState[this.getAppPath()] || {}, {
          diagramId: this.currentPageDiagram?.id,
          pageId: this.currentPage?.id,
          diagramStorage: this.getStorage("diagram")?.getStorageData(),
        })

        // Save current fragment state before navigation
        if(this.currentPage?.id) {
          this.renderer.currentState[this.getAppPath() + "." + this.currentPage?.id] = {
            fragmentStorage: this.$refs[this.currentPage?.id]?.storage?.getStorageData(),
          }
        }
      }

      // Check if navigation changed
      let navChanged = false;

      // Apply navigation
      const block = this.renderer.a2u.blocks[blockId];
      const toNode = block?.node;

      // Check if diagram
      const isDiagram = toNode?.type === 'DiagramComponent';

      const {isEmbedded, isModalDialog} = toNode?.properties || {};

      // Check if diagram already shown
      if (isDiagram && this.currentPageDiagram?.id === toNode?.id && !isEmbedded && !isModalDialog) {
        console.error("Diagram already shown", toNode?.id);

        if (this?.renderer?.a2u?.runMode !== 'release') {
          this.renderer.a2u.debugLogger.log({
            type: 'error',
            message: `Diagram already shown: ${toNode?.id}`,
            data: JSON.parse(JSON.stringify(toRaw(({
              processNum: null,
              diagram: { id: block?.diagramId },
              component: {
                id: blockId,
                properties: this.renderer.a2u.patchComponentProperties(this, params || {}),
              },
              data: {},
            })))),
          });
        }

        return;
      }

      // Store incoming params
      this.diagramIncomingParams = params;

      // Set current page
      if (isDiagram) {
        this.currentPageDiagram = toNode;
        if (!isEmbedded) this.currentPage = false;
        this.currentPageDiagramKey = this.currentPageDiagram?.id + Math.random();

        // Trigger transition
        if (!isEmbedded) {
          this.transition.key++;
        }
      } else {
        // Show fragment
        if (this.currentPage?.id !== toNode?.id) navChanged = true;
        this.currentPage = toNode;
        this.currentPageKey = this.currentPage?.id + this.renderer.a2u.getCurrentSessionId();

        // Trigger transition
        if (navChanged) {
          this.transition.key++;
        }

        this.currentPageDiagram = false

        // Notify parent that it is not a UI-less diagram
        this.$emit('fragment-shown');
      }

      //if(params !== undefined) this.childDiagramParams = params;
      if (event !== undefined) this.childDiagramStartEvent = event;

      // Check if debug state is presents
      if (this.renderer?.currentState) {

        // Save current diagram params to app state
        Object.assign(this.renderer.currentState[this.getAppPath()], {
            diagramId: this.currentPageDiagram?.id,
            pageId: this.currentPage?.id
          })
      }

      // Return changed flag
      return navChanged;
    },

    /**
     * Process outgoing links
     * @param context
     * @param blockId
     * @param event
     * @param data
     */
    processOutgoingLinks(context, blockId, data, event) {
      this.renderer.a2u.processOutgoingLinks(this, context, blockId, data, event);
    },

    /**
     * Handles actions to be performed before the transition enters.
     * Adds the 'overflow-hidden' class to the body to prevent scrolling.
     */
    transitionBeforeEnter() {
      document.body.classList.add('overflow-hidden');
      this.transition.active = true;
    },

    /**
     * Handles actions to be performed after the transition enters.
     * Removes the 'overflow-hidden' class from the body to allow scrolling.
     */
    transitionAfterEnter() {
      document.body.classList.remove('overflow-hidden');
      this.transition.active = false;
    },
  },

  computed: {

    /**
     * Get db changes
     * @return {*}
     */
    dbChanges() {
      return AbOrmVueQueryProcessor.modelsUpdateTriggers;
    },

    /**
     * Child items
     * @return {(function(): *)|[]|HTMLCollection|*}
     */
    items() {
      return this.diagramObj?.source?.children
    }
  },
}

</script>


<style lang="scss">

.modal-sidebar {
  .q-dialog__inner--minimized {
    padding: 0;
    min-width: 70%;
  }
}

body.q-ios-padding {
  .fullscreen.modal-modal, .modal-modal .q-dialog__inner {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .modal-modal .q-dialog__inner > div {
    max-height: 100% !important;
  }
}

.fragment-modal .q-dialog__backdrop {
  background: var(--fragment-modal-overlay-color, rgba(0,0,0,0.4));
}

.fragment-modal.mw-small .q-dialog__inner:not(.q-dialog__inner--maximized) > div { max-width: 50vw; width: 50vw; }
.fragment-modal.mw-medium .q-dialog__inner:not(.q-dialog__inner--maximized) > div { max-width: 75vw; width: 75vw; }
.fragment-modal.mw-large .q-dialog__inner:not(.q-dialog__inner--maximized) > div { max-width: 90vw; width: 90vw; }

.diagram-holder {
  display: flex;
  flex-direction: column;
  //flex-grow: 1;
  width: 100%;
  height: 100%;
}

.fragment-modal.mh-auto .q-dialog__inner:not(.q-dialog__inner--maximized) .diagram-holder {
  height: auto;
}

.fragment-modal.mh-full .q-dialog__inner:not(.q-dialog__inner--maximized) > div {
  height: 100%;
}


</style>
