<template>

  <ab-flow-base-cmp class="day-calendar-cmp" :block="block" :class="classesString" :style="stylesString">
    <q-scroll-area ref="mainScrollArea" class="daily-calendar col" :content-style="{maxWidth: '100%'}">
      <div class="calendar-hours" :style="cssVars">
        <div class="hours-grid">
          <div v-for="hour in hours" :key="hour" class="hour-label" @click="addEvent(hour)" v-text="formatHour(hour)" />
        </div>

        <q-scroll-area class="events-grid full-height full-width">
          <div ref="eventsContainer" class="events-grid__container full-height dg-extended" :class="{'opacity-0': !visibility}">
            <div v-for="hour in hours" :key="hour" class="hour-slot" @click="addEvent(hour)" />

            <div
              v-for="task in eventsList"
              :key="task.id"
              class="task text-no-wrap"
              :class="[`task_${task.status}`]"
              :ref="`eventRefs[${task.id}]`"
              @click.stop.prevent="eventClicked(task)"
            >
              <div class="task-title">
                <q-icon v-if="task?.recurrence" name="repeat" />
                {{ task.title }}
              </div>
              <div class="task-time text-caption">
                {{ formatTime(task.start_time) }} - {{ formatTime(task.end_time) }}
              </div>
            </div>
          </div>
        </q-scroll-area>
      </div>
    </q-scroll-area>
  </ab-flow-base-cmp>

</template>

<script>
import {toRaw} from 'vue';
import moment from 'moment';
import AbFlowBaseCmp from "../../../components/Containers/Designer/AbFlowBaseCmp.vue";
import {renderMixins} from "../../../components/renderMixins";
import {DayCalendarService} from './DayCalendarService';

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "DayCalendarEditorCmp",
  data() {
    return {
      hours: Array.from({ length: 24 }, (_, i) => i),
      rowHeight: 54,
      visibility: false,
      eventsList: [],
      service: null,
    };
  },
  computed: {
    /**
     * Computes CSS variables for task colors and backgrounds based on block properties.
     *
     * @returns {Object} An object containing CSS variable definitions.
     */
    cssVars() {
      return {
        '--task-color-active': `var(--foreground-color-${this.block?.properties?.taskColorActive || 'light'})`,
        '--task-color-completed': `var(--foreground-color-${this.block?.properties?.taskColorCompleted || 'light'})`,
        '--task-color-skipped': `var(--foreground-color-${this.block?.properties?.taskColorSkipped || 'light'})`,

        '--task-bg-active': `var(--background-color-${this.block?.properties?.taskBgActive || 'info'})`,
        '--task-bg-completed': `var(--background-color-${this.block?.properties?.taskBgCompleted || 'success'})`,
        '--task-bg-skipped': `var(--background-color-${this.block?.properties?.taskBgSkipped || 'secondary'})`,
      }
    },

    /**
     * Computes the selected date.
     *
     * @returns {number} The Unix timestamp of the selected date or the start of the current day.
     */
    selectedDate() {
      return this.getValue(this.block?.properties?.date) || moment().startOf('day').unix();
    },

    /**
     * Determines if the 24-hour format is enabled.
     *
     * @returns {boolean} True if 24-hour format is enabled, otherwise false.
     */
    enable24HourFormat() {
      return this.block?.properties?.enable24HourFormat === 1;
    },
  },

  methods: {
    /**
     * Initializes the DayCalendar component.
     * This method sets up the DayCalendarService instance.
     *
     * @async
     * @throws Will log an error if initialization fails.
     */
    async init() {
      try {
        this.service = new DayCalendarService({
          a2u: this.renderer?.a2u,
        });
      } catch (e) {
        this.logError('Error initializing DayCalendar component');
      }
    },

    /**
     * Loads events for the selected date.
     * This method fetches the events from the service and updates the eventsList.
     */
    async loadEvents() {
      this.eventsList = await this.service.loadEvents(this.selectedDate);
    },

    /**
     * Creates a time string in the format "hour:minute".
     *
     * @param {number} hour - The hour part of the time.
     * @param {number} minute - The minute part of the time.
     * @returns {string} The formatted time string.
     */
    createTime(hour, minute) {
      return hour +":" + minute
    },

    /**
     * Handles the click event on a task.
     *
     * @param {Object} event - The event object representing the clicked task.
     */
    eventClicked(event) {
      this.parentDiagram.processOutgoingLinks(this, this.block.id, {event_id: event.id}, "task-details")
    },

    /**
     * Adds a new event at the specified hour.
     *
     * @param {number} hour - The hour at which to add the event.
     */
    addEvent(hour) {
      this.parentDiagram.processOutgoingLinks(this, this.block.id, {
        start_date: this.selectedDate,
        start_time: this.createTime(hour, 0),
        end_time: this.createTime(hour + 1, 0)
      }, "add-task");
    },

    /**
     * Formats the given hour into a string representation.
     *
     * @param {number} hour - The hour to format.
     * @returns {string} The formatted hour string.
     */
    formatHour(hour) {
      if (this.enable24HourFormat) {
        return `${String(hour).padStart(2, '0')}:${hour < 23 ? '00' : '59'}`;
      }

      return hour === 0 ? '12 AM' :
        hour < 12 ? `${hour} AM` :
          hour === 12 ? '12 PM' :
            `${hour - 12} PM`;
    },

    /**
     * Formats the given time into a string representation.
     *
     * @param {string} time - The time to format, in "HH:mm" format.
     * @returns {string} The formatted time string.
     */
    formatTime(time) {
      return moment(time, "HH:mm").format(this.enable24HourFormat ? "HH:mm" : "hh:mm A");
    },

    /**
     * Converts a time string in "HH:mm" format to the total number of minutes.
     *
     * @param {string} time - The time string to convert.
     * @returns {number} The total number of minutes.
     */
    timeToMinutes(time) {
      try {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      } catch (e) {
        this.logError('Error converting time to minutes', time);
        return 0;
      }
    },

    /**
     * Calculates the position of an event in the calendar.
     *
     * @param {Object} event - The event object containing start and end times.
     * @param {string} event.start_time - The start time of the event in "HH:mm" format.
     * @param {string} event.end_time - The end time of the event in "HH:mm" format.
     * @returns {Object} An object containing the top position and height of the event.
     */
    calculateEventPosition(event) {
      const startMinutes = this.timeToMinutes(event.start_time);
      const endMinutes = this.timeToMinutes(event.end_time);
      const top = (startMinutes / 60) * this.rowHeight;
      const height = ((endMinutes - startMinutes) / 60) * this.rowHeight;

      return { top, height };
    },

    /**
     * Recalculates the positions of events in the calendar.
     * This method organizes events into columns to avoid overlapping and adjusts their positions and widths accordingly.
     */
    recalculatePositions() {
      const gap = 5;

      // Get the container element
      const container = this.$refs.eventsContainer;

      if (!container) {
        return;
      }

      // Reset the container width
      container.style.removeProperty('width');

      // Get the current container width
      let containerWidth = container.offsetWidth;

      // Get the list of events and calculate their start and end times in minutes
      const events = this.eventsList.map((event) => ({
        ...event,
        startMinutes: this.timeToMinutes(event.start_time),
        endMinutes: this.timeToMinutes(event.end_time),
      }));

      events.sort((a, b) => a.startMinutes - b.startMinutes);

      const columns = [];
      const columnWidths = [];

      // Iterate over each event and place it in a column
      events.forEach((event) => {
        const eventEl = this.$refs[`eventRefs[${event.id}]`][0];

        eventEl.removeAttribute('style');

        const eventWidth = eventEl.offsetWidth;

        let placed = false;

        for (let i = 0; i < columns.length; i++) {
          const column = columns[i];
          const isOverlapping = column.some(
            existingEvent =>
              existingEvent.endMinutes > event.startMinutes &&
              existingEvent.startMinutes < event.endMinutes
          );
          if (!isOverlapping) {
            column.push(event);
            placed = true;

            if (eventWidth > columnWidths[i]) {
              columnWidths[i] = eventWidth;
            }
            break;
          }
        }

        if (!placed) {
          columns.push([event]);
          columnWidths.push(eventWidth);
        }
      });

      // Calculate the total width of all columns
      const totalWidth = columnWidths.reduce((sum, width) => sum + width, 0) + gap * (columnWidths.length - 1);

      // Adjust the container width if necessary
      if (totalWidth > containerWidth) {
        containerWidth = totalWidth;
        container.style.width = `${containerWidth}px`;
      }

      // Calculate the scale factor to fit the columns within the container
      const scaleFactor = containerWidth / totalWidth;

      // Position the events in the columns
      columns.forEach((column, columnIndex) => {
        const left = columnWidths.slice(0, columnIndex).reduce((sum, width) => sum + width * scaleFactor + gap, 0);
        const columnWidth = columnWidths[columnIndex] * scaleFactor;

        column.forEach((event) => {
          const { top, height } = this.calculateEventPosition(event);

          const eventEl = this.$refs[`eventRefs[${event.id}]`][0];

          eventEl.style.top = `${top}px`;
          eventEl.style.left = `${left}px`;
          eventEl.style.height = `${height}px`;
          eventEl.style.width = `${columnWidth}px`;
        });
      });
    },

    logError(message) {
      try {
        const block = this.renderer?.a2u?.blocks?.[this.block?.id] || undefined;

        this.renderer.a2u.debugLogger.log({
          type: 'error',
          message,
          data: JSON.parse(JSON.stringify(toRaw(({
            processNum: null,
            diagram: { id: block?.diagramId },
            component: {
              id: this.block?.id,
              properties: this.renderer.a2u.patchComponentProperties(this, this.block?.properties || {}),
            },
            data: {},
          })))),
        });
      } catch (e) {
        console.error('Error logging error', e);
      }
    },

    /**
     * Scrolls the main scroll area to the current time if the selected date is today.
     */
    scrollToCurrentTime() {
      try {
        if (moment().startOf('day').unix() !== moment(this.selectedDate * 1000).startOf('day').unix()) {
          return;
        }

        const currentHour = moment().hour();

        this.$refs.mainScrollArea.setScrollPosition('vertical', currentHour * this.rowHeight, 0);
      } catch (e) {
        console.error('Error scrolling to current time', e);
      }
    },
  },

  watch: {
    async selectedDate() {
      await this.loadEvents();

      this.scrollToCurrentTime();
    },

    eventsList() {
      this.visibility = false;

      this.$nextTick(() => {
        try {
          this.recalculatePositions();

          this.visibility = true;
        } catch (e) {
          console.error('Error recalculating positions', e);

          this.logError('Error recalculating positions');
        }
      });
    },
  },

  async created() {
    await this.init();
  },

  async mounted() {
    await this.loadEvents();

    this.scrollToCurrentTime();
  },
}
</script>

<style lang="scss">
.day-calendar-cmp {
  flex-direction: column;
  position: relative;

  .calendar-hours {
    height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 70px 1fr;
  }

  .hour-label, .hour-slot {
    height: 54px;
    border-bottom: 1px solid #f0f0f0;
  }

  .hour-label {
    width: 70px;
    padding: 5px;
    border-right: 1px solid #f0f0f0;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .task {
    flex-grow: 1;
    background-color: #e6f2ff;
    border: 1px solid #3399ff;
    border-radius: 4px;
    padding: 5px;
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    z-index: 2;

    &_active {
      color: var(--task-color-active);
      background-color: var(--task-bg-active);
      border-color: var(--task-bg-active);
    }

    &_completed {
      color: var(--task-color-completed);
      background-color: var(--task-bg-completed);
      border-color: var(--task-bg-completed);
    }

    &_skipped {
      color: var(--task-color-skipped);
      background-color: var(--task-bg-skipped);
      border-color: var(--task-bg-skipped);
    }

    &__title {
      font-weight: bold;
      margin-bottom: 3px;
    }

    &__time {
      font-size: 10px;
      color: inherit;
    }
  }

  .events-grid {
    &__container {
      min-width: 100%;
    }

    .q-scrollarea__content {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
