import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class ForgotPasswordProcessor extends BaseComponentProcessor {
    /**
     * Process incoming event
     */
    async processEvent() {
        // Get the endpoint URL
        const endpointUrl = this.context?.renderer?.a2u?.authUrl;

        // If the endpoint URL is not set, return an error
        if (!endpointUrl) {
            return this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }

        const styles = `
          .dg-text-weight-thin {
            font-weight: 100;
          }
        
          .dg-text-weight-regular {
            font-weight: 400;
          }
        
          .dg-text-weight-bold {
            font-weight: 700;
          }
          ${this.context.renderer.a2u.getHtmlStyles()}
        `;

        // Get the email properties
        const email = this.context.interpretString(this.block?.properties?.userEmail);
        const from = this.context.interpretString(this.block?.properties?.from);
        const subject = this.context.interpretString(this.block?.properties?.subject);
        const sourceHtml = this.context.interpretString(this.block?.properties?.html);
        const html = sourceHtml ? `
            <html lang="${this.context.renderer.currentLanguage || 'en'}">
                <head><style>${styles}</style></head>
                <body>${sourceHtml}</body>
            </html>
        ` : null;

        // If any of the email properties are not set, return an error
        if (!email || !from || !subject || !html) {
            const notFilledProperties = Object.entries({
                sender: !!from,
                recipient: !!email,
                subject: !!subject,
                body: !!html,
            }).filter(([, value]) => !value).map(([key]) => key).join(', ');

            this.context.renderer.a2u.debugLogger.log({
                type: 'error',
                message: `Missing email properties: ${notFilledProperties}`,
                data: JSON.parse(JSON.stringify({
                    processNum: this.processNum,
                    diagram: { id: this.context?.currentDiagram?.block?.properties?.diagramComponentId },
                    component: {
                        id: this.block?.id,
                        properties: this.context.renderer.a2u.patchComponentProperties(this, this.block?.properties || {}),
                    },
                    data: {},
                })),
            });

            return this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }

        try {
            const payload = [{ email, from, subject, html }];

            // Send the email
            await this.context?.app?.client?.getAxios().post(`${endpointUrl}/forgot-password/restore`, payload, {
                headers: {
                    'x-api-key': process.env.VUE_APP_A2U_ACCESS_TOKEN,
                },
            });

            return this.processOutgoingLinks(this.context, this.block.id, null, 'success');
        } catch (e) {
            if (e?.response?.data?.exception?.code === 'not-found') {
                return this.processOutgoingLinks(this.context, this.block.id, null, 'not-found');
            }

            return this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }
    }
}
