// js type => application type
const typeMatching = {
    object: ['datetime', 'object', 'image', 'sound', 'icon', 'lottie', 'db-record'],
    boolean: ['bool'],
    number: ['int', 'float', 'datetime'],
    string: ['string'],
};

export const formComponentsMixins = {

    inject: {

        // Looking for parent form to perform submission
        parentForm: {
            default: null
        }
    },

    props: {
        name: {
            type: String,
            default: null
        },
        modelValue: {}
    },

    data: () => ({
        defaultValue: undefined
    }),

    computed: {

        /**
         * Current form control value
         */
        currentValue: {
            get: function () {
                // Check for form values or regular model
                const res = this.getValue(this.block?.properties?.field);
                return res === undefined || res === null ? this.defaultValue : res;
            },
            // setter
            set: function (newVal) {
                try {
                    // If the application is not in release mode, log the finish of the flow
                    if (this?.renderer?.a2u?.runMode !== 'release') {
                        const varMeta = this.getVarMeta(this.block?.properties?.field)
                        const targetType = varMeta?.type;

                        let mismatch;

                        if (varMeta?.isArray === 1 && Array.isArray(newVal)) {
                            mismatch = newVal.some(val => typeMatching[typeof val] && !typeMatching[typeof val].includes(targetType));
                        } else {
                            mismatch = typeMatching[typeof newVal] && !typeMatching[typeof newVal].includes(targetType);
                        }

                        // Check for type mismatch
                        if (mismatch) {
                            const block = this.renderer.a2u.blocks[this.block?.id] || undefined;
                            const cmp = block?.node;

                            // Log the type mismatch
                            this.renderer.a2u.debugLogger.log({
                                type: 'warning',
                                message: 'Type mismatch',
                                data: JSON.parse(JSON.stringify({
                                    processNum: null,
                                    diagram: { id: block.diagramId },
                                    component: {
                                        id: this.block?.id,
                                        properties: this.renderer.a2u.patchComponentProperties(this, cmp.properties || {}),
                                    },
                                    data: {
                                        newValue: newVal,
                                    },
                                })),
                            });
                        }
                    }
                } catch (e) {
                    console.error('formComponentMixin.currentValue.set check types:', e);
                }

                // Set value
                if(this.setValue(this.block?.properties?.field, newVal)) {
                    // Emit change event
                    this.parentDiagram.processOutgoingLinks(this, this.block.id, newVal, "changed")
                }
            }
        }
    }
}
