<template>
  <div
    class="fragment-editor-cmp"
    :class="[
      'fragment-'+fragmentType + ' '+classesString,
      {
        'fragment--safe-area': safeAreaInsets,
      }
    ]"
    :style="stylesString"
  >
    <atu-components-renderer :items="block.children"/>
  </div>
</template>

<script>
import {toRaw, onBeforeUnmount, getCurrentInstance} from 'vue';
import AtuComponentsRenderer from "../../../AtuComponentsRenderer";
import {renderMixins} from "../../renderMixins";

export default {
  mixins: [renderMixins],
  components: {AtuComponentsRenderer},
  provide() {
    return {
      parentFragment: this,
      parentSafeAreaInsets: this.currentSafeAreaInsets || this.parentSafeAreaInsets || false,
    }
  },
  inject: {
    parentSafeAreaInsets: {
      default: false,
    },
    parentTeleport: {
      default: false,
    },
  },
  props: {
    block: {},
  },
  name: "FragmentRendererCmp",

  setup() {
    const instance = getCurrentInstance();

    onBeforeUnmount(() => {
      // Check if the current diagram has debug app state
      if (instance?.proxy?.renderer?.currentState) {
        // Reset storage
        instance.proxy.renderer.currentState[instance.proxy.getAppPath()] = false;

      }

      // Call on unload event
      instance.proxy.removeNavigateBackListeners();
    });
  },

  data() {
    return {
      stateKey: 0,
      storage: {},
      isShown: false,
      ignoreWatchVars: new Set(),
      unknownDescriptorVars: new Set(),
      stateVariablesDefinitions: new Map(),
      stateDbModels: new Map(),
    };
  },

  created() {
    // Check if fragment is modal
    if(this.fragmentType !== "modal") {
      this.isShown = true;

      // Hide loader if exists
      this.renderer.a2u.hideLoader();
    }

    // Check if the current diagram has debug app state
    if (this.renderer?.currentState && !this.parentWidget) {

      // Get debug params
      const params = this.renderer.currentState[this.getAppPath()];

      // If params are set, navigate to the diagram or fragment
      if (params?.fragmentStorage && this.getStorage("fragment")) {
        this.getStorage("fragment").storage = params.fragmentStorage;
        console.log("Set fragment storage", params.fragmentStorage, this.getStorage("fragment"))
      }
    }

    // Load state variables definitions
    this.loadStateVarsDefinitions();
  },

  computed: {
    /**
     * Determines if the safe area insets are disabled.
     *
     * @returns {boolean} True if the safe area insets are disabled, false otherwise.
     */
    disableSafeAreaInsets() {
      return this.block?.properties?.disableSafeAreaInsets === 1
    },

    /**
     * Determines if the fragment type requires safe area insets.
     *
     * @returns {boolean} True if the fragment type requires safe area insets, false otherwise.
     */
    currentSafeAreaInsets() {
      return ['page', 'sidebar'].includes(this.fragmentType) && !this.disableSafeAreaInsets;
    },

    /**
     * Computed property that determines if the safe area insets should be applied.
     *
     * This property returns true if the current fragment requires safe area insets and the parent fragment does not.
     * Safe area insets are used to avoid content being obscured by the device's status bar, notch, or other intrusions.
     *
     * @returns {boolean} True if the safe area insets should be applied, false otherwise.
     */
    safeAreaInsets() {
      return this.currentSafeAreaInsets && !this.parentSafeAreaInsets && !this.parentTeleport;
    },

    /**
     * This computed property is used to determine the type of the fragment.
     * The type of the fragment is retrieved from the `fragmentType` property of the block.
     * If the `fragmentType` property is not set, the default value 'page' is returned.
     *
     * @return {string} - Returns the type of the fragment.
     */
    fragmentType() {
      return this.block?.properties?.fragmentType || 'page';
    },

    /**
     * Computed property that returns a list of state variables.
     *
     * This property traverses the state blocks and collects all the variables
     * that are not references and are not ignored.
     *
     * @returns {Array} An array of state variables.
     */
    stateVariables() {
      if (!this.stateVariablesDefinitions.size && !this.stateDbModels.size) {
        return [];
      }

      const result = [];

      this.stateVariablesDefinitions.forEach((value, nodeId) => {
        if (this.ignoreWatchVars.has(nodeId)) {
          return;
        }

        result.push(this.getValue(value));
      });

      this.stateDbModels.forEach((model) => {
        result.push(model?.query()?.depends());
      });

      return result;
    }
  },

  mounted() {

    // Call on load event
    this.onLoad();

    // Register "Navigation back" listeners
    this.addNavigateBackListeners();
  },
  methods: {
    /**
     * On load even
     */
    onLoad() {
      this.parentDiagram.processOutgoingLinks(this, this.block.id, false,"create")
    },

    /**
     * Adds the navigate back listeners.
     */
    addNavigateBackListeners() {
      if (this.renderer.a2u.getDevice()?.getPlatform() === 'web') {
        return;
      }

      const App = this.renderer.a2u.getDevice()?.getPlugin?.("App");

      App?.addListener('backButton', this.onNavigateBack);
    },

    /**
     * Removes the navigate back listeners.
     */
    removeNavigateBackListeners() {
      if (this.renderer.a2u.getDevice()?.getPlatform() === 'web') {
        return;
      }

      const App = this.renderer.a2u.getDevice()?.getPlugin?.("App");

      App?.removeListener('backButton', this.onNavigateBack);
    },

    /**
     * Trigger the "navigate-back" event on the fragment.
     */
    onNavigateBack() {
      this.parentDiagram.processOutgoingLinks(this, this.block.id, false, "navigate-back")
    },

    /**
     * Loads the state blocks by traversing the provided state links.
     *
     * This method recursively traverses the links and adds the target blocks to a set.
     *
     * @param {Array} stateLinks - An array of link objects containing the target blocks.
     * @returns {Set} A set of blocks that are linked to the provided state links.
     */
    loadStateBlocks(stateLinks) {
      const blocks = new Set();

      /**
       * Recursively traverses the links and adds the target blocks to the set.
       *
       * @param {Object} link - The link object containing the target block.
       */
      const deepLinks = (link) => {
        blocks.add(link.target);

        const links = Object.values(this.renderer.a2u?.links?.outgoing?.[link.target] || {}).flat();

        links.forEach(link => {
          deepLinks(link);
        });
      };

      stateLinks.forEach(link => {
        deepLinks(link);
      });

      return blocks;
    },

    /**
     * Loads the state variables definitions by traversing the state links.
     *
     * This method retrieves the state links for the current block and loads the state blocks
     * associated with those links. It then traverses each block to find variables that are not
     * references and are not setters, and adds them to the state variables definitions map.
     */
    loadStateVarsDefinitions() {
      const stateLinks = this.renderer.a2u?.links?.outgoing?.[this.block.id]?.state || [];

      if (!stateLinks.length) {
        return;
      }

      const blocks = this.loadStateBlocks(stateLinks);

      if (!blocks.size) {
        return;
      }

      /**
       * Recursively traverses the object to find variables and adds them to the state variables definitions map.
       *
       * @param {Object} obj - The object to traverse.
       */
      const goDeep = (obj) => {
        if (obj?.valueType === 'variable') {
          if (!obj?.isReference && obj?.descriptor !== 'setter') {
            this.stateVariablesDefinitions.set(obj.nodeId, obj);
          } else if (!this.ignoreWatchVars.has(obj.nodeId)) {
            if ('descriptor' in obj) {
              this.addVariableToWatchIgnore(obj);
            } else {
              this.unknownDescriptorVars.add(obj.nodeId);
            }
          }
        }

        if (obj && typeof obj === 'object') {
          Object.values(obj).forEach((value) => {
            goDeep(value);
          });
        }
      };

      blocks.forEach((blockId) => {
        const block = this.renderer.a2u?.blocks?.[blockId];

        if (!block) {
          return;
        }

        goDeep(block);

        // Check if the block is a DbQuery and add the model to the stateDbModels map
        if (block?.node?.type === 'DbQuery') {
          const dbId = block?.node?.properties?.dbId || 0;
          const tableId = block?.node?.properties?.tableId || 0;

          const model = this.renderer.a2u?.dbs?.[dbId]?.models?.[tableId];

          if (!this.stateDbModels.has(tableId) && model) {
            this.stateDbModels.set(tableId, model);
          }
        }
      });
    },

    /**
     * Adds a variable to the ignore watch list.
     *
     * This method adds the variable identified by `nodeId` to the `ignoreWatchVars` set,
     * preventing it from being watched for changes.
     *
     * @param {Object} variableData - The data object containing the variable information.
     * @param {string} variableData.nodeId - The unique identifier of the variable.
     */
    addVariableToWatchIgnore(variableData) {
      const nodeId = variableData?.nodeId;

      if (!nodeId || this.ignoreWatchVars.has(nodeId) || !this.unknownDescriptorVars.has(nodeId)) {
        return;
      }

      console.error(`Ignore watch for variable: ${nodeId}`, variableData);

      if (this?.renderer?.a2u?.runMode !== 'release') {
        const block = this.renderer.a2u.blocks[this.block?.id] || undefined;

        this.renderer.a2u.debugLogger.log({
          type: 'warning',
          message: `Ignore watch for variable: ${nodeId}`,
          data: JSON.parse(JSON.stringify(toRaw(({
            processNum: null,
            diagram: { id: block?.diagramId },
            component: {
              id: this.block?.id,
              properties: this.renderer.a2u.patchComponentProperties(this, this.block?.properties || {}),
            },
            data: {},
          })))),
        });
      }

      this.ignoreWatchVars.add(nodeId);
    },

    /**
     * Adds the variable identified by `nodeId` to the `ignoreWatchVars` set
     * before setting the operation value, preventing it from being watched for changes.
     *
     * @param {Object} variableData - The data object containing the variable information.
     */
    beforeSetOperationValue(variableData) {
      this.addVariableToWatchIgnore(variableData);
    },

    /**
     * Adds the variable identified by `nodeId` to the `ignoreWatchVars` set
     * before setting the value, preventing it from being watched for changes.
     *
     * @param {Object} variableData - The data object containing the variable information.
     */
    beforeSetValue(variableData) {
      this.addVariableToWatchIgnore(variableData);
    },
  },

  watch: {
    stateVariables: {
      handler: function () {
        console.log(`Fragment state changed: ${this.block.id}`);
        this.parentDiagram.processOutgoingLinks(this, this.block.id, false, "state");
      },
      deep: true,
      immediate: true,
    },
  },
}

</script>

<style lang="scss">
.fragment-page {
  width: 100%;
}

.fragment-editor-cmp {
  display: flex;
  flex-direction: column;
  color: var(--foreground-color-primary);
  flex-grow: 1;

  &.fragment-sidebar {
    background: var(--background-color-primary);
    color: var(--foreground-color-primary);
    height: 100%;
    max-height: none;
  }

  &.fragment--safe-area {
    padding-top: var(--safe-area-top);
    padding-left: var(--safe-area-left);
    padding-right: var(--safe-area-right);
    //overflow: hidden;
    //height: calc(var(--screen-height, 100vh) - var(--keyboard-height, 0px));
    //min-height: calc(var(--screen-height, 100vh) - var(--keyboard-height, 0px));
    //max-height: calc(var(--screen-height, 100vh) - var(--keyboard-height, 0px));
  }
}

/*.fragment-scroll-area {
  height: 100%;

  & > .q-scrollarea__container {
    & > .q-scrollarea__content {
      display: flex;
      flex-direction: column;
    }
  }
}*/
</style>
