<template>

  <ab-flow-base-cmp :block="block" class="button-cmp" :class="containerClasses" :style="buttonStyles">
    <q-btn
      :style="buttonSize"
      :no-caps="isButtonNoCaps"
      v-bind:[buttonShape]="true"
      v-bind:[buttonType]="true"
      v-bind:[buttonStyle]="true"
      v-on="btnEvents"
      :class="buttonClasses"
      :icon="buttonIcon"
      :loading="buttonLoadingState"
      v-bind="dynamicProps"
    ><div>{{ buttonLabel }}</div></q-btn>
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import {constants} from "../../../constants"

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block', 'type'],
  inject: {
    parentForm: {
      default: null
    }
  },
  name: "ButtonEditorCmp",

  data() {
    return {
      btnEvents: {},
    };
  },

  methods: {

    /**
     * Click button
     */
    clickButton(e) {
      e.preventDefault();
      e.stopPropagation();

      // Set progress state
      if (this.block?.properties?.progressState?.valueType === 'variable') {
        this.setValue(this.block?.properties?.progressState, true);
      }

      if(this.type === 'submit' || this.isSubmitButton) {
        if(this.parentForm) this.parentForm.submit();
      }

      this.parentDiagram.processOutgoingLinks(this, this.block.id, false, "click");
    }
  },
  computed: {

    /**
     * Button classes
     */
    buttonClasses() {
      return [
        this.getClasses({backgroundColor: true, textColor: true}),
        ...this.iconClasses,
        this.btnPadding,
      ];
    },

    /**
     * Button classes
     */
    containerClasses() {
      return [
        this.getClasses(false, {backgroundColor: true, textColor: true}),
        `button-cmp--${this.buttonStyle || 'default'}`,
        `button-cmp--type-${this.buttonType || 'regular'}`,
      ]
    },

    /**
     * Button label
     * @return {*|string}
     */
    buttonLabel() {
      return this.interpretString(this.block.properties?.label);
    },

    /**
     * Button icon
     * @return {*|string}
     */
    buttonIcon() {
      if (this.block?.properties?.iconType === 'image') {
        const image = this.renderer.a2u.assetPath(this.block?.properties?.iconImage);

        return image ? `img:${image}` : undefined;
      }

      return this.block?.properties?.icon || undefined;
    },

    /**
     * Subtitle
     * @return {string}
     */
    subtitle() {
      return this.block.properties?.subtitle || "";
    },

    /**
     * Button size
     * @return {*|string}
     */
    buttonSize() {
      if (!this.block?.properties?.btnSize) {
        return {};
      }

      return {'font-size': `${constants.size_to_font_size[this.block?.properties?.btnSize || 'md']}px`};
    },

    /**
     * Button color
     * @return {*|string}
     */
    buttonColor() {
      return this.block.properties ? this.block.properties["color"] : "";
    },

    /**
     * Button shape
     * @return {*|string}
     */
    buttonShape() {
      return this.block.properties ? this.block.properties["shape"] : "";
    },

    /**
     * Button type
     * @return {*|string}
     */
    buttonType() {
      return this.block.properties ? this.block.properties["type"] : "";
    },

    /**
     * Is button no-caps
     * @return {boolean}
     */
    isButtonNoCaps() {
      return this.block.properties ? this.block.properties["noCaps"] === 1 : false;
    },

    /**
     * Button loading state.
     * This method retrieves the value of the 'progressState' property from the block's properties.
     * If the 'progressState' property is truthy, it returns true; otherwise, it returns false.
     * @returns {boolean} The loading state of the button.
     */
    buttonLoadingState() {
      return !!this.getValue(this.block?.properties?.progressState);
    },

    /**
     * Retrieves the icon position for the button.
     * If the `iconPosition` property is not set, defaults to 'left'.
     *
     * @returns {string} The position of the icon.
     */
    iconPosition() {
      return this.block?.properties?.iconPosition || 'left';
    },

    /**
     * Retrieves the icon offset for the button.
     * If the `iconOffset` property is not set or is 'none', returns null.
     * Otherwise, returns the value of the `iconOffset` property.
     *
     * @returns {string|null} The offset of the icon.
     */
    iconOffset() {
      const offset = this.block?.properties?.iconOffset || null;

      return !offset || offset === 'none' ? null : offset;
    },

    /**
     * Retrieves the size of the icon for the button.
     * If the `iconSize` property is not set or is 'none', returns null.
     * Otherwise, returns the value of the `iconSize` property.
     *
     * @returns {string|null} The size of the icon.
     */
    iconSize() {
      const size = this.block?.properties?.iconSize || null;

      return !size || size === 'none' ? null : size;
    },

    /**
     * Computes the CSS classes for the button icon.
     *
     * @returns {Array} An array of CSS class names.
     */
    iconClasses() {
      const classes = [];

      // If there is no button icon, return an empty array.
      if (!this.buttonIcon) {
        return classes;
      }

      // Add the class for the icon position.
      classes.push(`button-cmp-icon-${this.iconPosition}`);

      // Add the class for the icon offset if it exists.
      if (this.iconOffset) {
        classes.push(`button-cmp-icon--offset`);
      }

      // Add the class for the icon size if it exists.
      if (this.iconSize) {
        classes.push(`button-cmp-icon--size`);
      }

      return classes;
    },

    /**
     * Computes the CSS styles for the button icon size.
     * If the `iconSize` property is not set, returns an empty object.
     * Otherwise, returns an object with the CSS variable for the icon font size.
     *
     * @returns {Object} The CSS styles for the button icon size.
     */
    iconSizeStyles() {
      if (!this.iconSize) {
        return {};
      }

      return {'--btn-icon-font-size': `${constants.size_to_font_size[this.iconSize] * 1.715}px`};
    },

    /**
     * Computes the CSS styles for the button icon offset.
     * If the `buttonIcon` or `iconOffset` properties are not set, returns an empty object.
     * Otherwise, returns an object with the CSS variable for the icon offset.
     *
     * @returns {Object} The CSS styles for the button icon offset.
     */
    iconOffsetStyles() {
      if (!this.buttonIcon || !this.iconOffset) {
        return {};
      }

      return {
        '--btn-icon-offset': `var(--dg-size-${this.iconOffset})`,
      };
    },

    /**
     * Determines if the button is a submit button.
     *
     * @returns {boolean} True if the button is a submit button, false otherwise.
     */
    isSubmitButton() {
      return this.block?.properties?.submitButton === 1;
    },

    /**
     * Retrieves the button style.
     * This method checks the `style` property of the block's properties.
     * If the `style` property is set to 'default', it returns an empty string.
     * Otherwise, it returns the value of the `style` property.
     *
     * @returns {string} The style of the button.
     */
    buttonStyle() {
      const style = this.block?.properties?.style || 'default';

      return style === 'default' ? '' : style;
    },

    /**
     * Computes the CSS styles for the button outline.
     * If the `buttonType` property is not 'outline', returns an empty object.
     * Otherwise, returns an object with the CSS variable for the button outline background color.
     *
     * @returns {Object} The CSS styles for the button outline.
     */
    outlineStyles() {
      if (this.buttonType !== 'outline') {
        return {};
      }

      const outlineStyles = {};

      const {backgroundColor, background} = this.block?.properties;

      if (backgroundColor) {
        outlineStyles['--button-outline-background-color'] = `var(--background-color-${backgroundColor})`;
      } else if (background?.color) {
        outlineStyles['--button-outline-background-color'] = background.color;
      }

      return outlineStyles;
    },

    /**
     * Computes the CSS styles for the button.
     * This method combines various style objects, including styles for the button outline, icon offset, and icon size.
     *
     * @returns {Object} The combined CSS styles for the button.
     */
    buttonStyles() {
      return {
        ...this.stylesString,
        ...this.outlineStyles,
        ...this.iconOffsetStyles,
        ...this.iconSizeStyles,
      };
    },

    /**
     * Computes the padding class for the button.
     * If the `btnPadding` property is not set or is 'none', returns `undefined`.
     * Otherwise, returns the padding class name.
     *
     * @returns {string|undefined} The padding class name or `undefined`.
     */
    btnPadding() {
      const padding = this.block?.properties?.btnPadding || null;

      return !padding || padding === 'none' ? undefined : `dg-pa-${padding}`;
    },
  },

  created() {
    // Set button events
    if (this?.block?.properties?.stopPropagation !== 1) {
      this.btnEvents = {
        'click': this.clickButton,
      };
    } else {
      this.btnEvents = {
        [`${this.renderer?.a2u?.getPlatform?.() === "web" ? 'mousedown' : 'touchstart'}`]: this.clickButton,
      };
    }
  },
}

</script>

<style lang="scss" scoped>
.button-cmp {
  position: relative;

  &.button-cmp--type-outline {
    background-color: transparent !important;
  }

  button {
    font-size: inherit;
    font-weight: inherit;
  }

  &:not(.button-cmp--round) {
    button {
      width: 100%;
    }
  }

  .subtitle {
    display: block;
    font-size: 12px;
  }

  // Click event
  .output {
    position: absolute;
    left: calc(50% - 5px);
    top: calc(50% - 5px);
  }

  .button-cmp-icon-top {
    &:deep(.q-btn__content) {
      flex-direction: column;
    }

    &.button-cmp-icon--offset {
      &:deep(.q-icon) {
        margin-bottom: var(--btn-icon-offset);
      }
    }
  }

  .button-cmp-icon-right {
    &:deep(.q-btn__content) {
      flex-direction: row-reverse;
    }

    &.button-cmp-icon--offset {
      &:deep(.q-icon) {
        margin-left: var(--btn-icon-offset);
      }
    }
  }

  .button-cmp-icon-bottom {
    &:deep(.q-btn__content) {
      flex-direction: column-reverse;
    }

    &.button-cmp-icon--offset {
      &:deep(.q-icon) {
        margin-top: var(--btn-icon-offset);
      }
    }
  }

  .button-cmp-icon-left {
    &.button-cmp-icon--offset {
      &:deep(.q-icon) {
        margin-right: var(--btn-icon-offset);
      }
    }
  }

  .button-cmp-icon--size {
    &:deep(.q-icon) {
      font-size: var(--btn-icon-font-size);
    }
  }

  &:deep(.q-btn--outline) {
    &:before {
      border-color: var(--button-outline-background-color);
    }

    .q-focus-helper {
      background-color: var(--button-outline-background-color) !important;
    }

    .q-ripple {
      color: var(--button-outline-background-color) !important;
    }
  }
}
</style>
