import moment from 'moment';
import {ErrorAnswer} from 'a2u-renderer-common/src/utils/ErrorAnswer';

/**
 * Service class for handling day calendar events.
 */
export class DayCalendarService {
  /**
   * Models for events and completed events.
   * @type {{events: null, completed: null}}
   */
  models = {
    events: null,
    completed: null,
  };

  /**
   * Constructor for DayCalendarService.
   * @param {Object} param0 - The parameter object.
   * @param {Object} param0.a2u - The a2u instance.
   * @throws {ErrorAnswer} If the database or models are not found.
   */
  constructor({a2u}) {
    this.a2u = a2u;

    const db = this.a2u?.getDb();

    if (!db) {
      throw new ErrorAnswer('Database not found', 'no_db');
    }

    this.models.events = Object.values(db.models).find((m) => m.entity === 'calendar_events');
    this.models.completed = Object.values(db.models).find((m) => m.entity === 'calendar_completed_events');

    if (!this.models.events || !this.models.completed) {
      throw new ErrorAnswer('Models not found', 'no_models');
    }
  }

  /**
   * Loads completed events for a given date.
   * @param {number} date - The date in Unix timestamp format.
   * @returns {Promise<Array>} The list of completed events.
   */
  async loadCompletedEvents(date) {
    try {
      if (!this.models.completed) {
        return;
      }

      const fromDate = moment(date * 1000).startOf('day').unix();
      const toDate = moment(date * 1000).endOf('day').unix();

      const sql = this.models.completed.sql();

      return await this.models.completed.query().where(
        sql('start_date >= ?', fromDate),
        sql('start_date <= ?', toDate)
      ).get();
    } catch (e) {
      console.error('Error loading completed events', e);

      return [];
    }
  }

  /**
   * Loads raw events for a given date.
   * @param {number} date - The date in Unix timestamp format.
   * @returns {Promise<Array>} The list of raw events.
   */
  async loadRawEvents(date) {
    try {
      if (!this.models.events) {
        return;
      }

      const fromDate = moment(date * 1000).startOf('day').unix();
      const toDate = moment(date * 1000).endOf('day').unix();

      const sql = this.models.events.sql();

      return await this.models.events.query().where(
        sql.or(
          sql.and(
            sql('start_date >= ?', fromDate),
            sql('start_date <= ?', toDate)
          ),
          sql.notEq('recurrence_rules', ''),
          sql.isNotNull('recurrence_rules')
        )
      ).get();
    } catch (e) {
      console.error('Error loading events', e);

      return [];
    }
  }

  /**
   * Loads all events (completed and raw) for a given date.
   * @param {number} date - The date in Unix timestamp format.
   * @returns {Promise<Array>} The list of events with their status.
   */
  async loadEvents(date) {
    const [completedEvents, rawEvents] = await Promise.all([
      this.loadCompletedEvents(date),
      this.loadRawEvents(date)
    ]);

    return rawEvents.filter((item) => {
      const recurrenceRules = item.recurrence_rules;

      // Filter out events that are not scheduled for the selected date
      if (!recurrenceRules) {
        return true;
      }

      // Filter out events that are not scheduled for the selected date
      if (item?.start_date && moment(item.start_date * 1000).startOf('day').unix() > date) {
        return false;
      }

      // Filter out events that are not scheduled for the selected date
      if (recurrenceRules?.until_time && moment(recurrenceRules.until_time * 1000).startOf('day').unix() < date) {
        return false;
      }

      // Filter out events that are not scheduled for the selected date
      if (recurrenceRules?.cycle === 'week') {
        const weekDays = recurrenceRules?.week_days || [];
        const weekDay = moment(date * 1000).format('dd').toLowerCase();

        if (!weekDays.includes(weekDay)) {
          return false;
        }
      } else if (recurrenceRules?.cycle === 'month') {
        // Filter out events that are not scheduled for the selected date
        const monthDays = recurrenceRules?.month_days || [];
        const monthDay = moment(date * 1000).date();

        if (!monthDays.includes(monthDay)) {
          return false;
        }
      }

      return true;
    }).map((item) => {
      const completedEvent = completedEvents.find(
        (e) => String(e.event_id) === String(item.id)
      );

      return {
        id: item.id,
        title: item?.title,
        start_time: item.start_time,
        end_time: item.end_time,
        status: completedEvent?.status || 'active',
        recurrence: !!item.recurrence_rules,
      }
    });
  }
}
