import {createApp} from 'vue'
import App from './App.vue'
import AppToUpRenderer from "../src/install.js"
import AbApp from "ab-application/src/install"
import db from "./db";
import {getConfig} from "./config";
import {ThreePlugin} from './ThreePlugin';

// Get segments
const [, mId, mode] = window.location.pathname.split('/')

// Create app
const app = createApp(App);

// Config
app.config.unwrapInjectedRef = true

// Include app to up renderer
app.use(AppToUpRenderer)

// Init app app, backendUrl, socketUrl, routes, translations, stateConfig, dbConfig
app.use(AbApp, {
        baseURL: process.env.VUE_APP_BACKEND_URL,
        //authURL: process.env.VUE_APP_DB_URL,
        config: async () => {
            return await getConfig(`app-settings-${mId}-${mode}`)
        },
        treeStorage: async () => {
          return await getConfig(`tree-storage-${mId}-${mode}`);
        },
        db,
        translations: { // initialize i18n instance
          language: 'en',
          resources: {
            ThreePlugin: ThreePlugin,
          }
        }
    },
    false, () => {

        // Mount app
        app.mount('#app');
    })
